<template>
  <div class="select-bill">
    <!-- 引入单据对话框 -->
    <CDialog :title="title" :showDialog.sync="openDialog" :width="1100" @close="handleClose" levelType>
      <div slot="content">
        <div class="top">
          <el-radio-group v-model="queryParams.sourceBillType" v-if="this.billType == '130105'" size="mini">
            <el-radio-button :label="140301">门店订货</el-radio-button>
            <el-radio-button :label="140302">门店原料订货</el-radio-button>
            <el-radio-button :label="170207">其他订货</el-radio-button>
            <el-radio-button :label="170208">其他原料订货</el-radio-button>
          </el-radio-group>
          <searchBox :dropDownList="dropDownList" placeholderText="请输入" @parameter="searchClick"
            @searchTextInput="searchTextInput" @searchInputEnter="searchInputEnter" class="marL10 marR10"
            ref="searchBox" />
          <el-button type="primary" icon="el-icon-search" size="mini" @click="searchInputEnter">搜索</el-button>
        </div>

        <div class="tableData">
          <el-table v-loading="loadingDia" :data="billList" height="500" @selection-change="handleSelectionChange"
            @select="selectChange" @select-all="selectAllChange" border max-height="500" ref="billsTable" class="table"
            :row-key="billType == '130106' ? 'sourceBillId' : 'billDetailId'" style="width: 100%">
            <el-table-column type="selection" width="50" align="center" :reserve-selection="true" />
            <el-table-column v-if="billType !== '130106'" :label="NoFields[0].label" align="center"
              :prop="NoFields[0].name" :show-overflow-tooltip="true" sortable width="160">
              <template slot-scope="scope">
                <el-button @click.native.prevent="handleDetail(scope.row)" type="text" class="use-select">
                  {{ scope.row[NoFields[0].name] }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column v-if="billType !== '130106'" :label="NoFields[1].label" align="center"
              :prop="NoFields[1].name" show-overflow-tooltip>
              <template slot-scope="scope">
                <dict-tag :options="dict.type.bill_status" :value="scope.row.billStatus" />
              </template>
            </el-table-column>
            <el-table-column v-if="['130105'].includes(billType)" label="单据日期" align="center" prop="billDate"
              :show-overflow-tooltip="true" width="120" />
            <el-table-column v-if="['130105'].includes(billType)" label="订货仓库" align="center" prop="storeName"
              :show-overflow-tooltip="true" width="120" />
            <el-table-column v-for="(item, index) in tableColumnFields" :key="index" :label="item.label" align="center"
              :prop="item.name" :show-overflow-tooltip="true" width="120" />
          </el-table>
          <div class="tag" @click="showPitchBills" ref="tag">
            <i class="el-icon-arrow-left" v-show="!openSelectBills"></i><i class="el-icon-arrow-right"
              v-show="openSelectBills"></i>
            <div class="right">
              <div>已</div>
              <div>选</div>
              <span>{{ ids.length }}</span>
            </div>
          </div>
          <div class="pitch" v-show="openSelectBills">
            <div class="pitch-title">
              <div class="left">选中的单据</div>
              <div class="right" @click="delPitchBills('#')">清空</div>
            </div>
            <el-scrollbar class="scrollbarPitch">
              <div class="itemList">
                <div class="item-goods" v-for="item in selectAllData" :key="item.billDetailId">
                  <div class="name">{{ item.goodsName }}</div>
                  <div class="el-icon-close" @click="delPitchBills(item)"></div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>

        <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="billTotal"
          :isCheck='this.selectAllData.length ?  true : false' @selectAllCom="bottomPageSelectAll" />
      </div>

      <div slot="buttonList">
        <el-button type="primary" @click="getBills">确 定</el-button>
      </div>
    </CDialog>
  </div>
</template>

<script>
import { uniqWith } from 'lodash' //去重
import { getSourceBill } from '@/api/purchase/index'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import bottomPage from '@/views/components/bottomPage' //底部分页
import json from 'highlight.js/lib/languages/json'
export default {
  name: 'SelectBill',
  dicts: ['bill_status'],
  components: {
    CDialog,
    bottomPage
  },
  props: {
    detailList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      required: true
    },
    //编号字段
    NoFields: {
      type: Array,
      required: true
    },
    //其他表格列字段
    tableColumnFields: {
      type: Array,
      required: true
    },
    //单据数据
    billList: {
      type: Array
    },
    //所有单据数组
    allBillList: {
      type: Array
    },
    //对话框开关
    openDialog: {
      type: Boolean,
      default: false
    },
    //遮罩层
    loadingDia: {
      type: Boolean,
      default: false
    },
    //单据数据总数
    billTotal: {
      default: 0
    },
    //单据类型
    billType: {
      type: String
    }
  },
  data() {
    return {
      //遮罩层
      loading: false,
      //搜索下拉框遮罩层
      selectLoading: false,
      //搜索单据关键词
      selectSortNo: undefined,
      //是否开启选中框
      openSelectBills: false,
      //定位已选图标的位置
      posiRight: '20px',
      //选中的数据
      selectAllData: [],
      //判断当前是否全选数据
      isCheckAll: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        sourceBillType: 140301,
      },
      //选中数据的id
      ids: [],
      //全部单据数据
      allBillData: [],
      form: {
        billDetailItems: []
      },
      //需要展开单据明细的id
      expandKeys: [],
      value: undefined,
      options: [],
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'billNo', name: '单据编号' },
        // { parameter: 'partnerName', name: '供应商名称' },
        { parameter: 'goodsNo', name: '商品编码' },
        { parameter: 'goodsName', name: '商品名称' }
      ],
    }
  },
  watch: {
    //获取传过来的全部单据数据
    allBillList: {
      handler(val) {
        console.log(val)
        let list = this.detailList.filter(item => item.sourceBillDetailId != undefined)
        console.log(this.detailList, 'detailList')
        console.log(list, 'list')
        this.selectAllData = [...this.selectAllData, ...list]
        this.selectAllData = uniqWith(this.selectAllData, (x, y) => x.sourceBillDetailId == y.sourceBillDetailId)
        console.log(this.selectAllData, 'selectAllData')
        if (this.selectAllData.length > 0) {
          this.$refs.billsTable.clearSelection()
          this.selectAllData.map((item, index) => {
            this.billList.map((v, i) => {
              if (item.sourceBillDetailId == v.sourceBillDetailId) {
                this.$nextTick(() => {
                  this.$refs.billsTable.toggleRowSelection(this.billList[i])
                })
              }
            })
          });
        }
        this.allBillData = val
      },
      immediate: true
    },
  },
  methods: {
    rowDataIndex({ row, rowIndex }) {
      // 给每行增加index参数，值为下标
      row.index = rowIndex + 1
    },
    searchTextInput(inputValue) {

      if (inputValue) {
        this.queryParams.query = inputValue
      } else {
        this.queryParams.billNo = undefined
        this.queryParams.goodsNo = undefined
        this.queryParams.goodsName = undefined
        this.queryParams.query = ''
      }
    },
    searchInputEnter() {
      this.$emit('search', this.queryParams)
    },
    searchClick(searchObj) {
      console.log('searchClick', searchObj);
      this.$emit('search', { ...searchObj, ...this.queryParams })
    },
    //下拉框值改变时触发
    remoteMethod(val) {
      console.log(this.value)
      this.selectLoading = false
      this.options = [
        { parameter: 'query', name: '全部' },
        { parameter: 'billNo', name: `单据编号:${this.value}` },
        { parameter: 'partnerName', name: '供应商名称' },
        { parameter: 'goodsNo', name: '商品编码' },
        { parameter: 'goodsName', name: '商品名称' }
      ]
    },
    //关闭对话框的回调
    handleClose() {
      this.selectAllData = []
      this.$refs.billsTable.clearSelection()
      this.$emit('update:openDialog', false)
      // this.$emit('close')
      // this.$refs.searchBox.reset()
    },
    //搜索框回车事件
    handleQuery() { },
    //表格多选框选中的数据
    handleSelectionChange(selection) {
      if (this.billType != '130106') {

        // this.selectAllData = selection
        this.selectAllData = [...this.selectAllData, ...selection]
        this.selectAllData = uniqWith(this.selectAllData, (x, y) => x.sourceBillDetailId == y.sourceBillDetailId)
        this.ids = selection.map(item => item.billDetailId)
        console.log('selectAllData 数据变', this.selectAllData, 'selectAllData')
      }
    },
    selectChange(selection, row) {
      if (this.billType == '130106') {
        console.log('selectChange', selection)
        let index = selection.findIndex(
          item => item.sourceBillId == row.sourceBillId
        )
        this.ids = selection.map(item => item.sourceBillId)
        this.selectAllData = [selection[index]]
        this.$refs.billsTable.clearSelection()
        this.$refs.billsTable.toggleRowSelection(selection[index])
      } else {
        let isCheck = selection.length && selection.indexOf(row) !== -1
        if (isCheck) {
          this.selectAllData = [...this.selectAllData, row]
        } else {
          console.log(this.selectAllData, 'this.selectAllData单选');
          this.selectAllData = this.selectAllData.filter(item => item.sourceBillDetailId != row.sourceBillDetailId)
        }
        // console.log('selectChange', selection)
        // let index = selection.findIndex(
        //   item => item.sourceBillId == row.sourceBillId
        // )
        // console.log('index', index)
      }
    },
    selectAllChange(selection) {
      if (this.billType == '130106') {
        this.selectAllData = []
        this.$refs.billsTable.clearSelection()
      } else {
        this.selectAllData = [...selection]
        console.log(this.selectAllData, 'this.selectAllData  全选')
      }
    },
    //进入单据详情页
    handleDetail() { },
    //显示选中的数据
    showPitchBills() {
      this.span = this.span === 20 ? 16 : this.span
      this.openSelectBills = !this.openSelectBills
      this.posiRight = this.posiRight === '20px' ? '232px' : '20px'
      this.$refs.tag.style.right = this.posiRight
    },
    //取消选中的数据
    delPitchBills(row) {
      if (row === '#') {
        this.$nextTick(() => {
          this.$refs.billsTable.clearSelection() //清空选中
        })
      } else {
        this.selectAllData.map(item => {
          if (item.billDetailId === row.billDetailId) {
            this.$nextTick(() => {
              this.$refs.billsTable.toggleRowSelection(item) //取消行选中
            })
          }
        })
      }
    },
    //点击全选所有数据
    selectAll() {
      this.isCheckAll = !this.isCheckAll
      if (this.isCheckAll) {
        /* 
        if(this.allBillData.length === 0){
          this.$emit('getAllBills',this.queryParams)
         } */
        this.allBillData.map(row => {
          if (this.selectAllData) {
            for (let i = 0; i < this.selectAllData.length; i++) {
              if (row.billDetailId === this.selectAllData[i].billDetailId)
                return
            }
          }
          this.$nextTick(() => {
            this.$refs.billsTable.toggleRowSelection(row, this.isCheckAll) //全选
          })
        })
      } else {
        this.$nextTick(() => {
          this.$refs.billsTable.clearSelection()
        })
      }
      this.getList()
    },
    //
    //搜索条件传递给主组件 重新渲染页面
    getList() {
      this.$emit('getSelect', this.queryParams)
    },
    employeeChange() {
      const purEmployeeId = ['110101', '110102', '140303']
      let type = 'purEmployeeId'
      if (!purEmployeeId.includes(this.billType)) {
        type = 'saleEmployeeId'
      }
      return type
    },
    storeChange() {
      const inStoreId = ['110102', '120103', , '140303']
      const outStoreId = ['110103', '120102']
      let type = 'storeId'
      if (inStoreId.includes(this.billType)) {
        type = 'inStoreId'
      } else if (outStoreId.includes(this.billType)) {
        type = 'outStoreId'
      }
      return type
    },
    customReflect(data) {
      // 因为详情接口没有进行统一修改，所以需要前端做映射
      const $this = this
      data.forEach(item => {
        item[$this.storeChange()] = item.storeId
        item[$this.employeeChange()] = item.deptId
      })
      return data
    },
    //选中商品
    getBills() {
      if (this.billType == '130106') {
        console.log('111');
        if (this.selectAllData[0].finishBillNo) return this.$message({ message: '只能引入状态为“未引入”的订单', type: 'warning' })
        getSourceBill('/api/system/source/bill/getSourceBillOrder', {
          billType: this.billType,
          billId: this.selectAllData[0].sourceBillId,
          pageNum: 1,
          pageSize: 99999
        }).then(response => {
          response.rows = response.rows.map(x => ({
            ...x,
            sourceBillType: x.billType
          }))
          this.$emit('getBillsIds', this.customReflect([...response.rows]))
        })
      } else {
        if (this.ids.length) {
          let list = uniqWith(this.selectAllData, (x, y) => x.sourceBillDetailId == y.sourceBillDetailId)

          this.$emit('getBillsIds', this.customReflect(list))
        }
      }
      this.handleClose()
      this.delPitchBills('#')
    },
    bottomPageSelectAll(isCheckAll) {
      if (isCheckAll) {
        this.billList.forEach(row => {
          this.$refs.billsTable.toggleRowSelection(row);
        });
      } else {
        this.selectAllData = []
        this.$refs.billsTable.clearSelection()
      }

    }
  },
}
</script>

<style lang="scss" scoped>
//顶部区域
.top {
  margin-bottom: 10px;
  display: flex;
}
//中间商品表格/选中商品区域
.tableData {
  display: flex;
  //表格区域
  .table {
    flex: 4;
    position: relative;
  }
  //右边选中区域
  .pitch {
    flex: 1;
    // height: 500px;
    width: 90%;
    border-top: 1px solid #dfe6ec;
    border-right: 1px solid #dfe6ec;
    border-bottom: 1px solid #dfe6ec;
    .pitch-title {
      display: flex;
      justify-content: space-between;
      padding: 0 5px;
      height: 55px;
      line-height: 55px;
      border-bottom: 1px solid #dfe6ec;
      background-color: #f8f8f9;
      .left {
        font-weight: 700;
      }
      .right {
        color: #1890ff;
        cursor: pointer;
      }
    }
    //滚动条
    .scrollbarPitch {
      max-height: 443px;
      overflow-y: scroll;
      .itemList {
        .item-goods {
          padding-right: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 56px;
          background-image: linear-gradient(to bottom, #fff, #f5f5f5);
        }
      }
    }
  }
}

//表格底部区域
.bill-footer {
  display: flex;
  justify-content: space-between;
  .left {
    padding: 32px 16px;
    .select {
      margin-right: 5px;
      .num {
        color: #1890ff;
      }
    }
    .checkAll {
      color: #1890ff;
      cursor: pointer;
    }
  }
}

//选中的图标
.tag {
  position: absolute;
  transform: translateY(-50%);
  right: 20px;
  top: 50%;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  width: 50px;
  border: 1px solid #dfe6ec;
  border-radius: 5px;
  align-items: center;
  z-index: 9999;
  background-color: #fff;
  .right {
    display: flex;
    flex-direction: column;
    padding: 0 2px;
    text-align: center;
  }
  span {
    padding: 0 6px;
    text-align: center;
    line-height: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #2386ee;
    color: #fff;
    font-size: 14px;
  }
}
.use-select {
  user-select: text;
}
</style>

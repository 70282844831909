<template>
  <div class="instore-detail app-container">
    <el-card class="card">
      <!-- 填写明细表单区域 -->
      <el-form
        ref="form"
        :model="form"
        label-width="90px"
        label-position="right"
        class="instore-form"
        :rules="rules"
      >
        <div
          class="bill-nav"
          :class="{
            'nav-audit-icon': form.billStatus == 2,
            'nav-stop-icon': stopOrder,
            'nav-noAudit-icon': noAuditOrder,
            'nav-delete-icon': deleteOrder,
          }"
        >
          <div class="info">
            <div class="searchItem div-number-item">
              <el-form-item label="单据编号" prop="billNo">
                <el-input
                  v-model="form.billNo"
                  placeholder="单据编号"
                  disabled
                ></el-input>
              </el-form-item>
            </div>
            <div class="searchItem">
              <el-form-item label="单据日期" prop="billDate">
                <el-date-picker
                  v-model="form.billDate"
                  type="date"
                  placeholder="选择日期"
                  :disabled="disabled"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="searchItem" v-if="BillSaleType">
              <el-form-item label=" 供应商 " prop="partnerId">
                <!-- 供应商自定义下拉框 -->
                <SelectFrame
                  ref="selectFrame"
                  v-model="form.partnerName"
                  :tableData="partnerData"
                  :disabled="disabled"
                  @getPitchId="getDiyselectId($event, 'partnerId', 'form')"
                  :router="'/partner/supplier/newSupplier'"
                  @click="openSelectSupplier = true"
                  :normalizer="normalizerSupp"
                ></SelectFrame>
              </el-form-item>
            </div>
            <div class="searchItem" v-if="!BillSaleType">
              <el-form-item label="客户" prop="partnerId">
                <!-- 客户自定义下拉框 -->
                <SelectFrame
                  ref="selectFrame"
                  v-model="form.partnerName"
                  :tableData="customerData"
                  :disabled="disabled"
                  @getPitchId="getDiyselectId($event, 'partnerId', 'form')"
                  :router="'/partner/supplier/newSupplier'"
                  @click="openSelectSupplier = true"
                  :normalizer="normalizerCus"
                ></SelectFrame>
              </el-form-item>
            </div>
            <div class="searchItem" v-if="storePostType === 'inStoreId'">
              <el-form-item label="仓库" prop="inStoreId">
                <!-- 仓库自定义下拉框 -->
                <SelectFrame
                  ref="selectStore"
                  v-model="form.inStoreName"
                  :tableData="storeData"
                  :disabled="disabled"
                  @getPitchId="getDiyselectId($event, 'inStoreId', 'form')"
                  @clickAdd="OpenStoreAdd = true"
                  @click="OpenStore = true"
                  :normalizer="normalizerStore"
                ></SelectFrame>
              </el-form-item>
            </div>
            <div class="searchItem" v-if="storePostType === 'outStoreId'">
              <el-form-item label="仓库" prop="outStoreId">
                <!-- 仓库自定义下拉框 -->
                <SelectFrame
                  ref="selectStore"
                  v-model="form.outStoreName"
                  :tableData="storeData"
                  :disabled="disabled"
                  @getPitchId="getDiyselectId($event, 'outStoreId', 'form')"
                  @clickAdd="OpenStoreAdd = true"
                  @click="OpenStore = true"
                  :normalizer="normalizerStore"
                ></SelectFrame>
              </el-form-item>
            </div>
            <div class="searchItem" v-if="storePostType === 'storeId'">
              <el-form-item label="仓库" prop="storeId">
                <!-- 仓库自定义下拉框 -->
                <SelectFrame
                  ref="selectStore"
                  v-model="form.storeName"
                  :tableData="storeData"
                  :disabled="disabled"
                  @getPitchId="getDiyselectId($event, 'storeId', 'form')"
                  @clickAdd="OpenStoreAdd = true"
                  @click="OpenStore = true"
                  :normalizer="normalizerStore"
                ></SelectFrame>
              </el-form-item>
            </div>
            <div class="searchItem div-number-item">
              <el-form-item label="手工单据号" prop="handBillNo">
                <el-input
                  v-model="form.handBillNo"
                  :disabled="disabled"
                  placeholder="手工单据号"
                  oninput="value=value.replace(/[^0-9a-zA-Z]/g,'')"
                ></el-input>
              </el-form-item>
            </div>
            <!--          </div>-->
            <!--          <div class="info">-->

            <div class="searchItem">
              <el-form-item label="部门" prop="purDeptId">
                <treeselect
                  v-if="['110101', '120101', '140313'].includes(billType)"
                  v-model="form.purDeptId"
                  class="treeselect"
                  :options="deptData"
                  :show-count="true"
                  placeholder="请选择部门"
                  :disabled="disabled"
                />
                <treeselect
                  v-else
                  v-model="form.deptId"
                  class="treeselect"
                  :options="deptData"
                  :show-count="true"
                  placeholder="请选择部门"
                  :disabled="disabled"
                />
              </el-form-item>
            </div>
            <div class="searchItem" v-if="BillSaleType">
              <el-form-item label="采购员" prop="purEmployeeId">
                <el-select
                  v-model="form.purEmployeeId"
                  placeholder="全部"
                  :disabled="disabled"
                  @blur="$refs.form.validateField('purEmployeeId')"
                  filterable
                  remote
                  reserve-keyword
                >
                  <el-option
                    v-for="item in employeeData"
                    :key="item.employeeId"
                    :label="item.employeeName"
                    :value="item.employeeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="searchItem" v-if="!BillSaleType">
              <el-form-item label="业务员" prop="saleEmployeeId">
                <el-select
                  v-model="form.saleEmployeeId"
                  placeholder="全部"
                  :disabled="disabled"
                  @blur="$refs.form.validateField('saleEmployeeId')"
                  filterable
                  remote
                  reserve-keyword
                >
                  <el-option
                    v-for="item in employeeData"
                    :key="item.employeeId"
                    :label="item.employeeName"
                    :value="item.employeeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!--          </div>-->
            <!--          <div class="info">-->
            <div class="searchItem Address">
              <el-form-item class="itemAddress" label="收货地址" prop="storeAddress">
                <el-input
                  v-model="form.storeAddressJoin"
                  readonly
                  style="width: 410px"
                  placeholder="请点击右边图标可编辑收货地址"
                  :disabled="disabled"
                >
                  <i slot="suffix" class="el-icon-more" @click="setAddress"></i>
                </el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <!-- 按钮区域 -->
        <div class="instore-btn">
          <div class="left-btn">
            <el-row :gutter="10" class="mb8">
              <el-col :span="1.5">
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-download"
                  size="mini"
                  :disabled="disabled"
                  @click="submitForm(false)"
                  >保存
                </el-button>
              </el-col>
              <el-col :span="1.5">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  plain
                  size="mini"
                  @click="AddBill"
                  >保存并新增
                </el-button>
              </el-col>
              <el-col :span="1.5">
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-edit"
                  size="mini"
                  @click="selectBills"
                  :disabled="disabled"
                  v-if="
                    billType !== '110101' &&
                    billType !== '120101' &&
                    billType !== '140313'
                  "
                  >引入源单
                </el-button>
              </el-col>
              <el-col :span="2.5">
                <el-dropdown
                  split-button
                  type="primary"
                  @click="changeBillStatus('审核')"
                  size="mini"
                  plain
                  icon="el-icon-circle-check"
                  :disabled="showAudit"
                  @command="changeBillStatus('反审核')"
                >
                  审核
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="反审核">反审核</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
              <el-col :span="1.5">
                <el-button
                  plain
                  icon="el-icon-switch-button"
                  size="mini"
                  :disabled="disabled"
                  @click="changeBillStatus('关闭')"
                  >关闭
                </el-button>
              </el-col>
            </el-row>
          </div>
          <div class="right-btn">
            <el-dropdown
              split-button
              type="primary"
              size="mini"
              plain
              v-print="PrintObj"
              disabled
            >
              打印
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="打印预览">打印预览</el-dropdown-item>
                <el-dropdown-item command="打印设置">打印设置</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- 表格数据区域 -->
        <div class="instore-table">
          <el-row>
            <el-col :span="24">
              <el-table
                class="table"
                ref="dragTable"
                :data="form.details"
                row-key="columnId"
                height="450"
                border
                show-summary
                :summary-method="getSummaries"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
              >
                <el-table-column align="center" width="80" :label="tableCellLabel">
                  <template v-slot="scope">
                    <i
                      v-if="scope.row.hoverRow && !disabled"
                      @click="row('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"
                    ></i>
                    <i
                      v-if="scope.row.hoverRow && !disabled"
                      @click="row('del', scope.$index)"
                      class="el-icon-remove operateDel"
                    ></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品编码"
                  width="160"
                  align="center"
                  prop="goodsNo"
                >
                  <template slot="header">
                    <i style="color: #ff4949">*</i>
                    商品编码
                  </template>
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="`details[${scope.$index}].goodsNo`"
                      :rules="rules[`details.goodsNo`]"
                    >
                      <el-input
                        v-model="scope.row.goodsNo"
                        :disabled="disabled"
                        @keyup.enter.native="keyup('goodsNo', $event, scope.$index, true)"
                        @blur="watchScroll"
                      >
                        <i
                          slot="suffix"
                          class="el-icon-more"
                          @click="openSelectGoods"
                        ></i>
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  show-overflow-tooltip
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  label="条码"
                  width="160"
                  align="center"
                  prop="barcode"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column label="数量" align="center" width="120" prop="unitQty">
                  <template slot="header">
                    <i style="color: #ff4949">*</i>
                    数量
                  </template>
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="`details[${scope.$index}].unitQty`"
                      :rules="rules[`details.unitQty`]"
                    >
                      <el-input
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                        v-model="scope.row.unitQty"
                        :disabled="disabled"
                        :sunyunFormat="(v) => $syInput(1, v)"
                        @blur="handleEdit(scope.row, 'unitQty', scope.$index)"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="单位" align="center" width="120">
                  <template slot="header">
                    <i style="color: #ff4949">*</i>
                    单位
                  </template>
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="`details[${scope.$index}].unitId`"
                      :rules="rules[`details.unitId`]"
                    >
                      <el-select
                        v-model="scope.row.unitId"
                        :disabled="disabled"
                        @blur="
                          $refs.form.validateField(`details[${scope.$index}].unitId`)
                        "
                        @change="
                          changeUnitPrice(
                            scope.row.unitId,
                            scope.row.units,
                            scope.$index,
                            true
                          )
                        "
                      >
                        <el-option
                          v-for="item in scope.row.units"
                          :key="item.unitId"
                          :label="
                            item.unitName == item.goodsSpec
                              ? item.unitName
                              : `${item.unitName} / ${item.goodsSpec}`
                          "
                          :value="item.unitId"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column
                  label="单价(元)"
                  align="center"
                  width="120"
                  value-key="duide"
                  prop="unitPrice"
                >
                  <template slot="header">
                    <i style="color: #ff4949">*</i>
                    单价(元)
                  </template>
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="`details[${scope.$index}].unitPrice`"
                      :rules="rules[`details.unitPrice`]"
                    >
                      <el-input
                        v-model="showForm.details[scope.$index].unitPrice"
                        :disabled="disabled"
                        @focus="
                          showFormatting(
                            3,
                            'focus',
                            $event,
                            showForm.details,
                            form.details,
                            'unitPrice',
                            scope.$index
                          )
                        "
                        :sunyunFormat="(v) => $syInput(2, v)"
                        @blur="
                          allFun(
                            scope.row,
                            'unitPrice',
                            $event,
                            'blur',
                            'unitPrice',
                            scope.$index
                          )
                        "
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="含税单价(元)"
                  align="center"
                  width="120"
                  prop="unitTaxPrice"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="showForm.details[scope.$index].unitTaxPrice"
                      :disabled="disabled"
                      @blur="
                        allFun(
                          scope.row,
                          'unitTaxPrice',
                          $event,
                          'blur',
                          'unitTaxPrice',
                          scope.$index
                        )
                      "
                      @focus="
                        showFormatting(
                          3,
                          'focus',
                          $event,
                          showForm.details,
                          form.details,
                          'unitTaxPrice',
                          scope.$index
                        )
                      "
                      :sunyunFormat="(v) => $syInput(2, v)"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  label="税率(%)"
                  align="center"
                  prop="taxRate"
                  width="120"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="showForm.details[scope.$index].taxRate"
                      :disabled="disabled"
                      @blur="
                        allFun(
                          scope.row,
                          'taxRate',
                          $event,
                          'blur',
                          'taxRate',
                          scope.$index
                        )
                      "
                      @focus="
                        showFormatting(
                          3,
                          'focus',
                          $event,
                          showForm.details,
                          form.details,
                          'taxRate',
                          scope.$index
                        )
                      "
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  label="税额"
                  align="center"
                  width="120"
                  prop="unitTaxMoney"
                >
                  <!-- 税额修改为展示 -->
                  <template slot-scope="scope">
                    {{ $syInput(3, callUnitTaxMoney(scope.row)) }}
                  </template>
                  <!-- <template slot-scope="scope">
                    <el-input
                      v-model="showForm.details[scope.$index].unitTaxMoney"
                      :disabled="disabled"
                      @blur="
                        allFun(
                          scope.row,
                          'unitTaxMoney',
                          $event,
                          'blur',
                          'unitTaxMoney',
                          scope.$index
                        )
                      "
                      @focus="
                        showFormatting(
                          3,
                          'focus',
                          $event,
                          showForm.details,
                          form.details,
                          'unitTaxMoney',
                          scope.$index
                        )
                      "
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                    ></el-input>
                  </template> -->
                </el-table-column>
                <el-table-column
                  label="价税合计(元)"
                  align="center"
                  prop="unitMoney"
                  width="120"
                >
                  <template slot-scope="scope">
                    <!-- {{ scope.row.unitPriceTaxMoney | numFilter }} -->
                    {{ $syInput(3, calUnitPriceTaxMoney(scope.row)) }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品批号"
                  align="center"
                  width="120"
                  prop="batchNo"
                >
                  <template v-slot="scope">
                    <el-input
                      oninput="value=value.replace(/[^0-9]/g,'')"
                      v-model="scope.row.batchNo"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  label="附加费"
                  align="center"
                  width="120"
                  prop="attachMoney"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="showForm.details[scope.$index].attachMoney"
                      :disabled="disabled"
                      ref="attachMoney"
                      @blur="
                        showFormatting(
                          3,
                          'blur',
                          $event,
                          showForm.details,
                          form.details,
                          'attachMoney',
                          scope.$index
                        )
                      "
                      @focus="
                        showFormatting(
                          3,
                          'focus',
                          $event,
                          showForm.details,
                          form.details,
                          'attachMoney',
                          scope.$index
                        )
                      "
                      :sunyunFormat="(v) => $syInput(3, v)"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                    ></el-input>
                  </template>
                </el-table-column>
                <slot name="tableSpecial"></slot>
                <slot name="tableBillSource"></slot>
                <el-table-column label="备注" align="center" width="200">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.remark" :disabled="disabled"></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </div>
        <div class="instore-footer">
          <!-- 附件上传组件 -->
          <accessoryUpload
            :disabled="disabled"
            :fileList="fileList"
            @getFileItems="getFileItemsData"
            @delFileItems="delFileItems"
          ></accessoryUpload>
          <div class="right-remark">
            <span> 单据备注:</span>
            <el-input
              class="input"
              placeholder="备注"
              :disabled="disabled"
              type="textarea"
              :rows="3"
              v-model="form.billRemark"
            ></el-input>
          </div>
        </div>
      </el-form>
      <!-- 选择商品信息框 -->
      <SelectGoods
        :OpenGoods.sync="OpenGoods"
        :queryCode="queryCode"
        :searchType="searchType"
        :isEnablePurchase="isEnablePurchase"
        :isEnableSale="isEnableSale"
        @getGoodsIds="getSelectIds($event, true)"
      ></SelectGoods>
      <!-- 引入源单框 -->
      <SelectBills
        :title="title"
        :NoFields="NoFields"
        :tableColumnFields="tableColumnFields"
        :billList="billList"
        :openDialog.sync="openBillDia"
        :loadingDia="loadingDia"
        :billTotal="billTotal"
        @getSelect="selectBills"
        :allBillList="allBillList"
        @getAllBills="getSelectBills"
        @getBillsIds="getSelectIds($event, true)"
        :billType="billType"
      ></SelectBills>
      <!-- 选择供应商对话框 -->
      <selectSupplier
        :openSelectSupplier.sync="openSelectSupplier"
        @getSupplierId="getSupplier($event, 'form')"
      ></selectSupplier>
      <!-- 选择仓库对话框 -->
      <selectStore
        :OpenStore.sync="OpenStore"
        @getStore="getStore($event, 'form', '', 'selectStore')"
      ></selectStore>
      <!-- 新增仓库对话框 -->
      <storeAdd
        :OpenStoreAdd.sync="OpenStoreAdd"
        @getStore="getStore($event, 'form')"
      ></storeAdd>
      <!-- 选择收货地址对话框 -->
      <el-dialog
        v-dialogDrag="true"
        title="收货地址"
        :visible.sync="openAddress"
        width="30%"
        class="AddressDialog"
      >
        <el-form ref="AddressForm" :model="form.storeAddress" label-width="80px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="联系人" prop="contactMan">
                <el-input
                  type="text"
                  v-model="form.storeAddress.contactMan"
                  placeholder="请输入联系人"
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="联系电话" prop="contactTel">
                <el-input
                  type="text"
                  v-model="form.storeAddress.contactTel"
                  placeholder="请输入联系电话"
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="门店地址" prop="storeAddress">
                <el-cascader
                  ref="cascader"
                  class="provinceListClass"
                  :options="provinceList"
                  :props="defaultProp"
                  v-model="form.storeAddress.regionId"
                  maxlength="80"
                  @change="getAddressId"
                  emitPath
                  v-loading="loading"
                >
                </el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="详细地址" prop="addressDetail">
                <el-input
                  type="text"
                  v-model="form.storeAddress.addressDetail"
                  placeholder="详细地址"
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveAddress">确 定</el-button>
          <el-button @click="openAddress = false">取 消</el-button>
        </div>
      </el-dialog>
    </el-card>
    <div class="footer">
      <div class="left"></div>
      <div class="right">
        <div class="item">
          <div class="label">单据金额 :</div>
          <div class="value">{{ billMoney }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  changeBillDetailType,
  allFun,
  getFileItemsData,
  delFileItems,
  watchScroll,
  scroll,
  destroyScroll,
  scrollEvent,
  savescroll,
  getRegion,
  setAddress,
  setAddressInfo,
  getAddress,
  getAddressId,
  saveAddress,
  getCurTime,
  handleEdit,
  getBillDetail,
  getSummaries,
  getSelectIds,
  keyup,
  getBasicData,
  openSelectGoods,
  selectBills,
  getSelectBills,
  reset,
  resetAddress,
  row,
  AddBill,
  // changeBillStatus,
  submitForm,
  getSupplier,
  getStore,
  getDiyselectId,
  changeUnitPrice,
} from "@/views/components/bill/public"; //方法统一存放的地方
import { showFormatting, getNewBillNo } from "@/utils/numFormatting";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import SelectGoods from "@/views/components/SelectGoods/SelectGoods"; //选择商品组件
import SelectBills from "@/views/components/SelectBills/SelectBills"; //选择单据组件
import selectSupplier from "@/views/components/selectSupplier"; //选择供应商组价
import selectStore from "@/views/components/selectStore"; //选择仓库组件
import storeAdd from "@/views/components/selectStore/storeAdd.vue"; //新增仓库组件
import accessoryUpload from "@/views/components/accessoryUpload";
import { updateInstoreStatus } from "@/api/purchase";
import log from "@/views/system/monitor/job/log.vue"; //附件上传组件
export default {
  name: "Detail",
  components: {
    SelectGoods,
    SelectBills,
    Treeselect,
    selectSupplier,
    accessoryUpload,
    selectStore,
    storeAdd,
  },
  dicts: ["bill_status"],
  props: {
    billType: {
      required: true,
      type: String,
    },
    searchType: {
      type: Number,
      default: null,
    },
  },
  data() {
    //自定义校验规则
    const setoodsNo = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error("请输入商品编码"));
        this.$message.error("请输入商品编码");
      } else {
        callback();
      }
    };
    const setUnitId = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error("请选择单位"));
        this.$message.error("请选择单位");
      } else {
        callback();
      }
    };
    const setUnitQty = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error("请输入数量"));
        this.$message.error("请输入数量");
      } else {
        callback();
      }
    };
    const setUnitPrice = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error("请输入单价"));
        this.$message.error("请输入单价");
      } else {
        callback();
      }
    };
    return {
      tableCellLabel: "序号",
      //打印
      PrintObj: {
        id: "print",
      },
      //仓库类型(出库,入库)
      storePostType: undefined,
      //单据类型
      TypeBill: undefined,
      //单据业务类型
      BillSaleType: undefined,
      //区分单价取进价/售价
      unitPriceType: undefined,
      //获取单据详情url
      urlDetail: undefined,
      //修改单据url
      urlUpdate: undefined,
      //新增单据url
      urlAdd: undefined,
      //更新单据状态url
      urlBillStatus: undefined,
      //引入单据url
      urlSourceBill: undefined,
      //过滤供应商的值
      supplierVal: undefined,
      //过滤仓库的值
      storeVal: undefined,
      //仓库业务  区别(采购/销售)业务
      showStoreSale: false,
      //单据id
      billId: undefined,
      //更新订单明细数据变动
      iskey: false,
      //打开选择供应商开关
      openSelectSupplier: false,
      //打开选择仓库开关
      OpenStore: false,
      //打开新增仓库开关
      OpenStoreAdd: false,
      //选择商品信息开关
      OpenGoods: false,
      //传送商品参数
      queryCode: undefined,
      //遮罩层
      loading: false,
      //禁用标识
      disabled: false,
      //标识已审核
      auditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已删除
      deleteOrder: false,
      //是否启用审核按钮
      showAudit: true,
      //当前表格宽度
      scrollLeft: null,
      //引入源单对话框表格列
      tableColumnFields: [],
      //引入源单框编号字段
      NoFields: [
        { label: "单据编号", name: "billNo" },
        { label: "单据状态", name: "billStatus" },
      ],
      //引入源单框单据数据
      billList: [],
      //引入源单对话框标题
      title: "",
      //引入源单对话框开关
      openBillDia: false,
      //引入源单对话框遮罩层
      loadingDia: false,
      //引入源单单据总数
      billTotal: undefined,
      //引入单据全部数据
      allBillList: [],
      //区别(采购/销售)/仓库时,获取收货地址
      diySelectType: true,
      //选择的类型(商品/引入源单)
      selectType: undefined,
      //自定义搜索框字段转换 (供应商)
      normalizerSupp: {
        Id: "supplierId",
        No: "supplierNo",
        Name: "supplierName",
      },
      //自定义搜索框字段转换 (客户)
      normalizerCus: {
        Id: "customerId",
        No: "customerNo",
        Name: "customerName",
      },
      //自定义搜索框字段转换 (仓库)
      normalizerStore: {
        Id: "storeId",
        No: "storeNo",
        Name: "storeName",
      },
      //收货地址对话框
      openAddress: false,
      //收货地址表单数据
      addressForm: {},
      //全部行政地区
      provinceList: [],
      //行政地址类型转换
      defaultProp: {
        value: "id",
        label: "label",
      },
      //省市区
      selectedOptions: [],
      //字典单位数据
      dictUnitData: [],
      //供应商数据
      partnerData: [],
      //客户数据
      customerData: [],
      //仓库数据
      storeData: [],
      //员工数据
      employeeData: [],
      //部门数据
      deptData: [],
      //区分需要的是(客户/供应商)数据
      getCusSupp: undefined,
      i: {},
      //input保留的小数位数
      decimalNum: 3,
      //需要进行input转换的数据
      showForm: {
        details: [{}],
      },
      //表单数据
      form: {
        purDeptId: undefined, // 门店ID
        deptId: undefined, // 门店ID
        //单据id
        billId: undefined,
        //制单日期
        billDate: undefined,
        //单据编号
        billNo: undefined,
        //供应商
        partnerId: undefined,
        //订单仓库
        storeId: undefined,
        //仓库(入库)
        inStoreId: undefined,
        //仓库(出库)
        outStoreId: undefined,
        //采购员
        purEmployeeId: undefined,
        //单据状态
        billStatus: "0",
        //制单人
        createEmployeeId: undefined,
        //审核人
        auditEmployeeId: undefined,
        //审核日期
        auditTime: undefined,
        //单据金额
        billMoney: "00:000",
        //备注
        billRemark: undefined,
        //单据表格-订单明细
        details: [{}],
        //上传的附件
        fileItems: [],
        //收货地址
        storeAddress: {
          contactMan: undefined,
          contactTel: undefined,
          regionId: undefined,
          addressDetail: undefined,
        },
        //收货地址拼接
        storeAddressJoin: undefined,
      },
      fileList: [], // 显示的文件
      isEnablePurchase: false,
      isEnableSale: false,
      //表单校验
      rules: {
        billNo: [{ required: true, message: "请到编码规则设置", trigger: "blur" }],
        billDate: [{ required: true, message: "请输入单据日期", trigger: "blur" }],
        billMoney: [{ required: true, message: "请输入单据金额", trigger: "blur" }],
        billNotaxMoney: [
          { required: true, message: "请输入单据不含税金额", trigger: "blur" },
        ],
        billQty: [{ required: true, message: "请输入单据编号", trigger: "blur" }],
        billTaxMoney: [
          { required: true, message: "请输入单据含税金额", trigger: "blur" },
        ],
        partnerId: [
          {
            required: true,
            message: "请选择",
            trigger: ["change", "blur"],
          },
        ],
        purEmployeeId: [{ required: true, message: "请选择采购员", trigger: "change" }],
        saleEmployeeId: [{ required: true, message: "请选择业务员", trigger: "change" }],
        inStoreId: [
          { required: true, message: "请选择仓库", trigger: ["change", "blur"] },
        ],
        outStoreId: [
          { required: true, message: "请选择仓库", trigger: ["change", "blur"] },
        ],
        storeId: [{ required: true, message: "请选择仓库", trigger: ["change", "blur"] }],
        "details.goodsNo": [{ validator: setoodsNo, trigger: ["change", "blur"] }],
        "details.unitId": [{ validator: setUnitId, trigger: "change" }],
        "details.unitQty": [{ validator: setUnitQty, trigger: "blur" }],
        "details.unitPrice": [{ validator: setUnitPrice, trigger: "blur" }],
      },
    };
  },
  filters: {
    //过滤3位小数,并四舍五入
    numFilter(value) {
      let realVal;
      if (value) {
        realVal = parseFloat(value).toFixed(3);
      } else {
        realVal = "";
      }
      return realVal;
    },
  },
  watch: {
    //侦听传过来的单据类型
    billType: {
      handler(newVal) {
        console.log("TypeBill  === ", newVal);
        this.changeBillDetailType(newVal);
        this.TypeBill = newVal;
        // 销售出库单明细
        if (
          newVal === "120101" ||
          newVal === "140313" ||
          newVal === "120102" ||
          newVal === "120103"
        ) {
          this.isEnablePurchase = null;
          this.isEnableSale = true;
        }
        // 采购
        if (newVal === "110101" || newVal === "110102" || newVal === "103") {
          this.isEnablePurchase = true;
          this.isEnableSale = null;
        }
      },
      immediate: true,
    },
    //侦听路由
    $route: {
      handler(to) {
        if (this.$route.query.type === "Update") {
          if (this.$route.query.billId) {
            this.billId = this.$route.query.billId;
            if (this.urlDetail) {
              this.getBillDetail(true);
            }
          }
        } else {
          this.reset();
          this.disabled = false;
          this.auditOrder = false;
          this.stopOrder = false;
          this.noAuditOrder = false;
          this.deleteOrder = false;
          this.getCurTime();
          console.log("-=采购单号-=-", this.TypeBill);
          this.getNewBillNo(this.form, "billNo", Number(this.TypeBill));
        }
      },
      //深度监听,同时也可监听到param参数变化
      deep: true,
      immediate: true,
    },
    //侦听是否开启审核按钮
    "form.billId": {
      handler(newVal) {
        if (
          newVal &&
          !(this.form.billStatus === "4") &&
          !(this.form.billStatus === "3")
        ) {
          this.showAudit = false;
        } else {
          this.showAudit = true;
        }
      },
      immediate: true,
    },
  },
  created() {
    console.log("我看看----123-------");
    this.getBasicData("detail", this.getCusSupp);
  },
  beforeDestroy() {
    //页面销毁移除监听表格滚动条事件
    this.destroyScroll();
  },
  mounted() {
    this.scroll(); // 监听表格滚动条滚动事件
  },
  computed: {
    //单据金额
    billMoney() {
      let data = this.form.details.filter((i) => {
        return i.unitMoney;
      });
      let data1 = this.form.details.filter((x) => {
        return x.attachMoney;
      });
      let res = data.reduce((prev, curr) => {
        return prev + Number(curr.unitMoney);
      }, 0);
      if (!(data1.length === 0)) {
        let res1 = data1.reduce((prev, curr) => {
          return prev + Number(curr.attachMoney);
        }, 0);
        res = res + res1;
      }
      const total = res ? res.toFixed(this.decimalNum) : "00:000";
      this.form.billMoney = total;
      return total;
    },
  },
  methods: {
    callUnitTaxMoney(row) {
      if (row.taxRate) {
        return this.$options.filters.numFilter(
          row.unitPrice * row.unitQty * (row.taxRate * 0.01)
        );
      } else {
        return 0;
      }
    },
    calUnitPriceTaxMoney(row) {
      return this.$options.filters.numFilter(row.unitTaxPrice * row.unitQty);
    },
    //计算生产日期 + 保质期天数 = 到期日
    computingTime(row) {
      if (row.produceDate && row.qualityDays) {
      }
    },
    //获取自定义搜索框选中的id  (供应商)
    getDiyselectId,
    //根据单据类型更改api
    changeBillDetailType,
    //格式化金额保留n位小数
    showFormatting,
    //统一调用 showFormatting handleEdit 方法  失去焦点自动计算并保留3位小数
    allFun,
    //获取附件上传的信息数据
    getFileItemsData,
    // 删除附件
    delFileItems,
    //表格滚动条位置
    //监听数据变化失去焦点时  滚动条的位置
    watchScroll,
    //监听滚动事件
    scroll,
    //移除监听滚动事件
    destroyScroll,
    // 获取滚动的宽度
    scrollEvent,
    // 数据刷新后设置滚动条当前滚动的位置
    savescroll,
    //获取全部行政地区事件
    getRegion,
    //设置收货地址
    setAddress,
    // 选中的仓库获取收货地址
    setAddressInfo,
    //获取收货地址
    getAddress,
    //收货地址省市区改变时获取省市区id
    getAddressId,
    //获取选中的供应商
    getSupplier,
    //获取选中的仓库
    getStore,
    //修改/编辑收货地址对话框
    saveAddress,
    //新增时获取当前时间
    getCurTime,
    //新增时获取单据编号
    getNewBillNo,
    //计算采购明细表格单价,含税价,税率，税额 根据两个值的变化自动计算
    handleEdit,
    //单价根据单位改变并显示保留3位小数
    changeUnitPrice,
    //根据单据id 获取单据详情
    getBillDetail,
    //订单明细表格合计
    getSummaries,
    //选中的商品/源单 数据
    getSelectIds,
    //表格单元格回车事件
    keyup,
    //获取下拉框数据
    getBasicData,
    //选择商品方法
    openSelectGoods,
    //引入源单按钮方法
    selectBills,
    //获取引入源单全部数据
    getSelectBills,
    //表单重置
    reset,
    //初始化收货地址表单
    resetAddress,
    //表格增加/减少一行方法
    row,
    //新增单据
    AddBill,
    ///更新单据状态 (审核,反审核,关闭)
    // changeBillStatus,
    /** 提交按钮 */
    submitForm,
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      //获取当前行下标
      let eleIndex = this.form.details.indexOf(row);
      // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
      let Arr = JSON.parse(JSON.stringify(this.form.details));
      for (let index = 0; index < Arr.length; index++) {
        const element = Arr[index];
        if (eleIndex == index) {
          element["hoverRow"] = true;
        } else {
          element["hoverRow"] = false;
        }
      }
      this.form.details = JSON.parse(JSON.stringify(Arr));
      this.tableCellLabel = "操作";
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.form.details.length; index++) {
        const element = this.form.details[index];
        element["hoverRow"] = false;
      }
      this.tableCellLabel = "序号";
    },
    async changeBillStatus(name, bool) {
      try {
        await this.$confirm(`确定${name}该单据, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });
        if (name === "审核") {
          let response = await updateInstoreStatus(
            [this.form.billId],
            "2",
            this.urlBillStatus,
            this.TypeBill
          );
          this.form.billStatus = response.data.billStatus;
          if (bool) {
            //有收货地址才进入  初始化收货地址
            if (
              response.data.storeAddress === null ||
              response.data.storeAddress === undefined
            ) {
              response.data.storeAddress = this.resetAddress();
            }
            this.getAddress();
          }

          this.disabled = true; //是否禁用
          this.auditOrder = this.form.billStatus; //审核图标
          this.noAuditOrder = false; //未审核图标
        } else if (name === "反审核") {
          let response = await updateInstoreStatus(
            [this.form.billId],
            "0",
            this.urlBillStatus,
            this.TypeBill
          );
          this.form.billStatus = response.data.billStatus;
          if (bool) {
            //有收货地址才进入   初始化收货地址
            if (
              response.data.storeAddress === null ||
              response.data.storeAddress === undefined
            ) {
              response.data.storeAddress = this.resetAddress();
            }
            this.getAddress();
          }
          this.disabled = false;
          this.auditOrder = false;
          this.noAuditOrder = true;
        } else if (name === "关闭") {
          console.log("审核8 == ", this.auditOrder);
          updateInstoreStatus(
            [this.form.billId],
            "4",
            this.urlBillStatus,
            this.TypeBill
          ).then((response) => {
            this.form = response.data;
            if (bool) {
              //有收货地址才进入   初始化收货地址
              if (
                response.data.storeAddress === null ||
                response.data.storeAddress === undefined
              ) {
                response.data.storeAddress = this.resetAddress();
              }
              this.getAddress();
            }
            this.disabled = true;
            this.showAudit = true;
            this.stopOrder = true;
          });
        }
        // 弹出提示
        this.$message.success(`${name}单据成功`);
      } catch {}
    },
  },
};
</script>
<style lang="scss" scoped>
.instore-detail {
  padding: 10px;
  background-color: #f3f3f3;

  .card {
    min-height: 90vh;
    padding: 10px;
    //顶部区域
    .bill-nav {
      width: 100%;

      .info {
        width: 80%;
        display: flex;
        flex-wrap: wrap;

        .searchItem {
          display: flex;
          align-items: center;
          text-align: left;

          &.Address {
            width: 50%;

            .itemAddress {
              width: 100%;
            }
          }

          .el-select,
          .treeselect,
          .el-input,
          ::v-deep.el-date-editor {
            width: 160px;
          }

          .label-name {
            min-width: 4vw;
            margin-right: 0.52vw;
            text-align: right;
            text-align-last: justify;
          }
        }
      }
    }

    //供应商下拉框
    ::v-deep.el-select-dropdown__list {
      min-width: 400px !important;
    }

    //未审核图标
    .nav-noAudit-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/noAudit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已审核图标
    .nav-audit-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/audit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已删除图标
    .nav-delete-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/delete.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已关闭图标
    .nav-stop-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/stop.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    // 按钮区域
    .instore-btn {
      display: flex;
      justify-content: space-between;

      .noAuditBtn {
        position: absolute;
        z-index: 2022;
        bottom: -30px;
        transform: translateX(70%);
        display: none;

        &.show {
          display: block;
        }
      }
    }

    // 表格区域
    .instore-table {
      ::v-deep.el-form-item {
        margin-bottom: 0;
      }

      ::v-deep.el-form-item__content {
        margin-left: 0 !important;
      }

      .table-icon {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          height: 64px;
          width: 165px;
          background: url(~@/assets/images/audit.png) no-repeat;
          background-size: 164px 63px;
          top: calc(48% - 32px);
          left: calc(50% - 80px);
        }
      }
    }

    .instore-footer {
      display: flex;
      width: 100%;
      font-size: 14px;
      color: #606266;
      font-weight: 700;
      margin-top: 15px;

      .left-accessory {
        display: flex;
        width: 50%;

        .btn {
          margin: 0 5px;
        }
      }

      .right-remark {
        width: 50%;
        display: flex;

        span {
          width: 10%;
        }

        .input {
          width: 90%;
        }
      }
    }
  }

  //底部区域
  .footer {
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-top: 1px solid #d6d8da;
    display: flex;

    .left {
      flex: 5;
    }

    .right {
      flex: 2;
      display: flex;

      .item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;

        .label {
          font-size: 14px;
        }
      }
    }
  }
}
</style>

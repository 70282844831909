import { render, staticRenderFns } from "./master-layout.vue?vue&type=template&id=61436448&scoped=true"
import script from "./master-layout.vue?vue&type=script&lang=js"
export * from "./master-layout.vue?vue&type=script&lang=js"
import style0 from "./master-layout.vue?vue&type=style&index=0&id=61436448&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61436448",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-pre/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61436448')) {
      api.createRecord('61436448', component.options)
    } else {
      api.reload('61436448', component.options)
    }
    module.hot.accept("./master-layout.vue?vue&type=template&id=61436448&scoped=true", function () {
      api.rerender('61436448', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/bill/master-layout.vue"
export default component.exports
<template>
  <div class="cropper-wrapper">
    <!-- element 上传图片按钮 -->
    <template class="pre-box">
      <el-upload ref="upload" class="upload-demo" action="" drag :on-change="handleChangeUpload" :disabled="disabled"
        :file-list="fileList" list-type="picture-card" :limit="limit" :on-preview="handlePreview"
        :on-remove="handleRemove" :on-exceed="handleExceed">
        <i class="el-icon-plus upload-demo-icon"></i>
        <div slot="tip" class="el-upload__tip tip">
          {{ `最多上传${limit}张,${explain}${otherTip}` }}
        </div>
      </el-upload>
    </template>
    <!-- <i class="el-icon-delete cursorP" /> -->
    <!-- vueCropper 剪裁图片实现-->
    <el-dialog title="图片剪裁" :visible.sync="dialogVisible" class="crop-dialog" append-to-body>
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <VueCropper ref="cropper" :img="option.img" :output-size="option.size" :output-type="option.outputType"
            :info="true" :full="option.full" :can-move="option.canMove" :can-move-box="option.canMoveBox"
            :original="option.original" :auto-crop="option.autoCrop" :fixed="option.fixed"
            :fixed-number="option.fixedNumber" :center-box="option.centerBox" :info-true="option.infoTrue"
            :fixed-box="option.fixedBox" :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight" @cropMoving="cropMoving" />
        </div>
      </div>
      <div class="action-box">
        <el-upload class="upload-demo" action="" :show-file-list="false" :on-change="handleChangeUpload">
          <el-button type="primary">更换图片</el-button>
        </el-upload>
        <!-- <el-button type="primary" @click="clearImgHandle">
          清除图片
        </el-button> -->
        <el-button type="primary" @click="rotateLeftHandle">
          左旋转
        </el-button>
        <el-button type="primary" @click="rotateRightHandle">
          右旋转
        </el-button>
        <el-button type="primary" @click="changeScaleHandle(1)">
          放大
        </el-button>
        <el-button type="primary" @click="changeScaleHandle(-1)">
          缩小
        </el-button>
        <el-button type="primary" @click="option.fixed = !option.fixed">
          {{ option.fixed ? '固定比例' : '自由比例' }}
        </el-button>
        <el-button type="primary" @click="downloadHandle('blob')">
          下载
        </el-button>
      </div>
      <div style="height: 30px"></div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="finish">
          确认
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisiblePrw">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
 
<script>
import Sortable from "sortablejs";
import { delOss, } from "@/api/ossfile"; //阿里云接口
import axios from 'axios'; // 引入 axios
import { VueCropper } from 'vue-cropper'
import { deepCopy, isArray, haveContent } from '@/utils';
export default {
  name: 'Cropper',
  components: {
    VueCropper,
  },
  model: {
    prop: 'value',
    event: 'modelValue'
  },
  props: {
    value: {
      type: [String, Array, Object],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 10,
    },
    explain: {
      type: String,
      default: "仅支持格式JPG、PNG、JPEG",
    },
    widthHeight: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisiblePrw: false,
      actionUrl: process.env.VUE_APP_BASE_API + "/api/open/oss/file/upload",
      isPreview: true,
      dialogVisible: false,
      previewImg: '', // 预览图片地址
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 该参数决定了裁剪后图片的缩放比例
        outputQuality: 1, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 835, // 默认生成截图框宽度
        autoCropHeight: 350, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      // 防止重复提交
      loading: false,
    };
  },
  computed: {
    fileList: {
      get() {
        return this.value
      },
      set(e) {
        this.$emit('modelValue', e)
      }
    },
    otherTip() {
      let t = " ";
      const [width, height] = this.widthHeight;
      if (haveContent(width) || haveContent(height)) {
        let maxW = Number(String(width).replace(/\D/g, ""));
        maxW = Number.isFinite(maxW) ? maxW : 0;
        if (maxW > 0) {
          t = `${t}${t ? "，" : ""}宽不能超过 ${maxW}px`;
        }
        let maxH = Number(String(height).replace(/\D/g, ""));
        maxH = Number.isFinite(maxH) ? maxH : 0;
        if (maxH > 0) {
          t = `${t}${t ? "，" : ""}高不能超过 ${maxH}px`;
        }
      }
      return t;
    },
  },
  mounted() {
    this.initDragSort();
  },
  methods: {
    handleExceed() {
      this.$modal.msgError(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    initDragSort() {
      const el = this.$refs.upload.$el.querySelectorAll('.el-upload-list')[0];
      Sortable.create(el, {
        onEnd: ({ oldIndex, newIndex }) => {
          // 交换位置
          const arr = this.fileList;
          const page = arr[oldIndex];
          arr.splice(oldIndex, 1);
          arr.splice(newIndex, 0, page);
        }
      });
    },
   async handleRemove(file) {
      const pathAndFilename = this.extractPathAndFilename(file.url);
       await delOss(pathAndFilename); // 调用删除接口，假设 delOss 返回一个 Promise
      this.fileList = this.fileList.filter(v => v.url !== file.url)
    },
   extractPathAndFilename(url) {
    // 假设URL的格式是：协议://域名/路径/文件名
    // 我们使用URL对象来更安全地处理URL
    const urlObj = new URL(url);
    // 提取路径和文件名（即URL的pathname属性）
    return urlObj.pathname;
 },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisiblePrw = true;
    },
    async deleteImage() {
      try {
        // 假设 this.previewImg 包含要删除的图片的URL或标识符
        if (this.previewImg) {
          this.loading = true; // 显示加载状态
          await delOss(this.previewImg); // 调用删除接口，假设 delOss 返回一个 Promise
          this.previewImg = ''; // 清空预览图片
          this.isPreview = false; // 设置为非预览状态
          this.$emit('input', '');
          this.$message.success('图片删除成功！'); // 提示成功消息
        } else {
          this.$message.warning('没有图片可以删除！'); // 如果没有图片可供删除，则提示警告消息
        }
      } catch (error) {
        console.error('删除图片过程中发生错误:', error); // 输出错误信息到控制台
        this.$message.error('图片删除失败！'); // 提示错误消息给用户
      } finally {
        this.loading = false; // 确保无论结果如何都隐藏加载状态
      }
    },
    // 上传按钮 限制图片大小和类型
    handleChangeUpload(file, fileList) {
      const isJPG =
        file.raw.type === 'image/png' || file.raw.type === 'image/png' || file.raw.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG/JPEG 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
        return false;
      }
      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(async () => {
        // base64方式
        // this.option.img = await fileByBase64(file.raw)
        this.option.img = URL.createObjectURL(file.raw);
        console.log('img', this.option.img);
        this.loading = false;
        this.dialogVisible = true;
      });
    },
    // 放大/缩小
    changeScaleHandle(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 左旋转
    rotateLeftHandle() {
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRightHandle() {
      this.$refs.cropper.rotateRight();
    },
    // 下载
    downloadHandle(type) {
      let aLink = document.createElement('a');
      aLink.download = 'author-img';
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          aLink.href = URL.createObjectURL(data);
          aLink.click();
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          aLink.href = data;
          aLink.click();
        });
      }
    },
    // 清理图片
    clearImgHandle() {
      this.option.img = '';
    },
    // 截图框移动回调函数
    cropMoving() {
      // 截图框的左上角 x，y和右下角坐标x，y
      // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
      // console.log(cropAxis)
    },
    async finish() {
      try {
        // 获取截图的 blob 数据
        const blob = await new Promise((resolve, reject) => {
          this.$refs.cropper.getCropBlob((blob) => {
            resolve(blob);
          }, reject);
        });
        // 创建 FormData 对象用于上传
        const formData = new FormData();
        formData.append('file', blob, 'cropped-image.png'); // 假设上传的文件名为 cropped-image.png
        // 发起上传请求到 OSS
        this.loading = true;
        const response = await axios.post(this.actionUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        // 处理上传响应
        if (response.data.code == 200) { // 假设服务器返回的数据结构包含 success 字段
          this.dialogVisible = false;
          let previewImg = URL.createObjectURL(blob); // 预览裁剪后的图片
          let obj = {
            name: '',
            url: response.data.data.accessUrl
          }
          // console.log('obj0.1', deepCopy(obj));
          // let list = this.fileList ? deepCopy(this.fileList) : []
          // console.log('obj0.2', deepCopy(list));
          this.fileList.push(obj);
          // console.log('list0.3', deepCopy(this.fileList));
          // this.$emit('input', list);
          this.$message.success('图片上传成功！');
        } else {
          this.$message.error('图片上传失败！');
        }
        this.loading = false;
      } catch (error) {
        console.error('上传过程中发生错误:', error);
        this.loading = false;
        this.$message.error('图片处理或上传出错！');
      }
    },
    cancel() {
      this.dialogVisible = false
    }
  },
};
</script>
 
<style lang="scss" scoped>
::v-deep .el-upload-dragger {
  width: 80px !important;
  height: 80px !important;
}
.cropper-wrapper {
  width: 100%;
  height: 100%;
  .upload-demo .el-upload {
    border: 1px dashed #8c939d;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .upload-demo .el-upload:hover {
    border-color: #8c939d;
  }
  .upload-demo-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .pre-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 80px;
      margin-top: 15px;
    }
  }
}

.crop-dialog {
  .cropper-content {
    padding: 0 40px;

    .cropper {
      width: auto;
      height: 350px;
    }
  }

  .action-box {
    padding: 25px 40px 0 40px;
    display: flex;
    justify-content: center;

    button {
      margin-right: 15px;
    }
  }

  .dialog-footer {
    button {
      width: 80px;
    }
  }
}
::v-deep .el-upload--picture-card {
  height: 80px;
  line-height: 86px;
  width: 80px;
  border: 0px solid #8c939d;
}

::v-deep .el-upload-list__item-actions {
  height: 80px;
  width: 80px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  height: 80px;
  width: 80px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  height: 80px;
  width: 80px;
}

::v-deep .el-upload-dragger:hover {
  border-color: #8c939d;
}

::v-deep .el-upload--picture-card:hover {
  border-color: #8c939d;
}
.tip {
  line-height: 20px;
  color: #c0c4cc;
}
</style>
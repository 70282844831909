var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "authorizationBinding" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _c("div", { staticClass: "choose-wrapper" }, [
        _c("div", { staticClass: "block" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v("新注册"),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "block" }, [
          _vm._m(2),
          _c(
            "div",
            { staticClass: "btn" },
            [_c("el-button", { attrs: { type: "primary" } }, [_vm._v("绑定")])],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header x-fc" }, [
      _vm._v(" 正在授权 "),
      _c("div", { staticClass: "icon" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/imgs/loginimgs/weixin-icon.svg"),
            alt: "加载失败",
          },
        }),
      ]),
      _vm._v(" 与 "),
      _c("div", { staticClass: "icon" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/imgs/loginimgs/product-icon.png"),
            alt: "加载失败",
          },
        }),
      ]),
      _vm._v(" 进行绑定 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("strong", { staticClass: "title" }, [_vm._v("注册新的账号")]),
      _c("div", [_vm._v("通过此流程进行注册的账号")]),
      _c("div", [_vm._v("将直接登录并与第三方账号完成绑定")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("strong", { staticClass: "title" }, [_vm._v("绑定已有的账号")]),
      _c("div", [_vm._v("登录已有账号与第三方账号完成绑定")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
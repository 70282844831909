var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accessory-upload" },
    [
      _vm.title != "" && !_vm.showUploadBtn
        ? _c("span", { staticClass: "marR15" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _c(
        "el-upload",
        {
          ref: "upload",
          class: [{ hide: _vm.noneBtnImg }],
          attrs: {
            "before-upload": _vm.handleBeforeUpload,
            "on-success": _vm.handleUploadSuccess,
            action: _vm.uploadFile,
            "on-error": _vm.handleUploadError,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "before-remove": _vm.beforeRemove,
            "show-file-list": "",
            multiple: "",
            limit: _vm.limit,
            "on-exceed": _vm.handleExceed,
            "file-list": _vm.fileList,
            disabled: _vm.disabled,
            "on-change": _vm.imgChange,
            "list-type": _vm.listType,
          },
        },
        [
          _vm.listType != "picture-card"
            ? _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled: _vm.disabled,
                  },
                },
                [_vm._v("点击上传 ")]
              )
            : _c("i", {
                staticClass: "el-icon-plus",
                attrs: { slot: "default" },
                slot: "default",
              }),
          ["text", "img"].includes(_vm.listType) && !_vm.showUploadBtn
            ? _c(
                "span",
                {
                  staticClass: "el-upload__tip tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    " (单个文件最大支持20M,支持格式PDF、Word、Excel、Txt、JPG、PNG、RAR、ZIP) "
                  ),
                ]
              )
            : _vm._e(),
          !_vm.isLimitMany && _vm.listType == "picture-card"
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        "最多上传" +
                          _vm.limit +
                          "张," +
                          _vm.explain +
                          _vm.otherTip
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="container padd10">
    <cardTitleCom cardTitle="搜索">
      <template slot="cardContent">
        <div class="padd10 x-f">
          <div style="display: flex; align-items: center; margin-right: 10px">
            <div class="textTime" style="margin-right: 15px">单据日期</div>
            <el-date-picker
              size="mini"
              v-model="datetime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </div>
          <div style="margin: 15px 10px 15px 0">
            <el-radio-group v-model="purSelectList.filterTime" size="mini">
              <el-radio-button label="today">今天</el-radio-button>
              <el-radio-button label="yesterday">昨天</el-radio-button>
              <el-radio-button label="thisWeek">本周</el-radio-button>
            </el-radio-group>
          </div>
          <div>
            <el-button
              type="primary"
              :icon="icon"
              size="mini"
              @click="showHighSearch"
              >高级搜索
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getList(urlList)"
              >搜索
            </el-button>
          </div>
        </div>
        <div v-show="showHigh" class="padd10">
          <!-- 销售/采购 搜索区域 -->
          <template v-if="!diySelectType">
            <div class="info x-w">
              <div class="searchItem div-number-item">
                <span class="label-name">单据编号</span>
                <el-input
                  size="small"
                  v-model="purSelectList.billNo"
                  placeholder="单据编号"
                ></el-input>
              </div>
              <div class="searchItem" v-if="ItemType">
                <span class="label-name">{{ '客户' }}</span>
                <!-- 客户自定义下拉框 -->
                <SelectFrame
                  ref="selectFrame"
                  v-model="supplierVal"
                  :tableData="customerData"
                  :disabled="false"
                  @getPitchId="
                    getDiyselectId($event, 'partnerId', 'purSelectList')
                  "
                  :router="'/partner/customer/newCustomers'"
                  :normalizer="normalizerCus"
                  @click="$router.push({ path: '/goods/partner/customer' })"
                ></SelectFrame>
              </div>
              <div class="searchItem" v-if="!ItemType">
                <span class="label-name">{{ '供应商' }}</span>
                <!-- 供应商自定义下拉框 -->
                <SelectFrame
                  ref="selectFrame"
                  v-model="supplierVal"
                  :tableData="partnerData"
                  @getPitchId="
                    getDiyselectId($event, 'partnerId', 'purSelectList')
                  "
                  :router="'/partner/supplier/newSupplier'"
                  @click="openSelectSupplier = true"
                  :normalizer="normalizerSupp"
                ></SelectFrame>
              </div>
              <div class="searchItem">
                <span class="label-name">单据状态</span>
                <el-select
                  v-model="purSelectList.billStatus"
                  placeholder="全部"
                  filterable
                  size="small"
                >
                  <el-option
                    v-for="dict in dict.type.bill_status"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="searchItem" v-if="showStore === 'storeId'">
                <span class="label-name">仓库</span>
                <SelectFrame
                  ref="selectStore"
                  v-model="purSelectList.storeName"
                  :tableData="storeData"
                  @getPitchId="
                    getDiyselectId($event, 'storeId', 'purSelectList')
                  "
                  @clickAdd="OpenStoreAdd = true"
                  @click="OpenStore = true"
                  :normalizer="normalizerStore"
                ></SelectFrame>
              </div>
              <div class="searchItem" v-if="showStore === 'inStoreId'">
                <span class="label-name">仓库</span>
                <SelectFrame
                  ref="selectStore"
                  v-model="purSelectList.inStoreName"
                  :tableData="storeData"
                  @getPitchId="
                    getDiyselectId($event, 'inStoreId', 'purSelectList')
                  "
                  @clickAdd="OpenStoreAdd = true"
                  @click="OpenStore = true"
                  :normalizer="normalizerStore"
                ></SelectFrame>
              </div>
              <div class="searchItem" v-if="showStore === 'outStoreId'">
                <span class="label-name">仓库</span>
                <SelectFrame
                  ref="selectStore"
                  v-model="purSelectList.outStoreName"
                  :tableData="storeData"
                  @getPitchId="
                    getDiyselectId($event, 'outStoreId', 'purSelectList')
                  "
                  @clickAdd="OpenStoreAdd = true"
                  @click="OpenStore = true"
                  :normalizer="normalizerStore"
                ></SelectFrame>
              </div>
              <div class="searchItem" v-if="showEmployee === 'pur'">
                <span class="label-name">采购员</span>
                <el-select
                  size="small"
                  v-model="purSelectList.purEmployeeId"
                  placeholder="全部"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in employeeData"
                    :key="item.employeeId"
                    :label="item.employeeName"
                    :value="item.employeeId"
                  ></el-option>
                </el-select>
              </div>
              <div class="searchItem" v-if="showEmployee === 'sale'">
                <span class="label-name">业务员</span>
                <el-select
                  size="small"
                  v-model="purSelectList.saleEmployeeId"
                  placeholder="全部"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in employeeData"
                    :key="item.employeeId"
                    :label="item.employeeName"
                    :value="item.employeeId"
                  ></el-option>
                </el-select>
              </div>
              <div class="searchItem">
                <span class="label-name">制单人</span>
                <el-select
                  size="small"
                  v-model="purSelectList.createEmployeeId"
                  placeholder="全部"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in employeeData"
                    :key="item.employeeId"
                    :label="item.employeeName"
                    :value="item.employeeId"
                  ></el-option>
                </el-select>
              </div>
              <div class="searchItem resetBtn">
                <el-button
                  class="reset"
                  icon="el-icon-refresh"
                  plain
                  size="mini"
                  @click="refresh('reset')"
                  >重置
                </el-button>
              </div>
            </div>
          </template>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="搜索">
      <template slot="cardContent">
        <div class="padd10">
          <div class="marB10">
            <el-button
              type="primary"
              icon="el-icon-plus"
              plain
              size="mini"
              @click="handleAdd(routerName)"
              v-if="billType != '000'"
              >新增
            </el-button>
            <el-dropdown
              class="marL10"
              split-button
              type="primary"
              size="mini"
              plain
              icon="el-icon-circle-check"
              :disabled="multiple"
              @click="handleBillStatus('审核', selectData, url, BillType)"
              @command="handleBillStatus('反审核', selectData, url, BillType)"
              v-if="billType != '000'"
            >
              审核
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="反审核">反审核</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              class="marL10"
              type="danger"
              plain
              icon="el-icon-delete"
              size="mini"
              :disabled="multiple"
              @click="handleBillStatus('删除', selectData, url, BillType)"
              v-if="billType != '000'"
              >删除
            </el-button>
            <el-button
              type="warning"
              plain
              icon="el-icon-switch-button"
              size="mini"
              :disabled="multiple"
              @click="handleBillStatus('关闭', selectData, url, BillType)"
              v-if="billType != '000'"
              >关闭
            </el-button>
            <el-button
              plain
              icon="el-icon-refresh"
              size="mini"
              @click="refresh('refresh')"
              v-if="billType != '000'"
              >刷新
            </el-button>
          </div>
          <!--列表-->
          <el-table
            :data="purList"
            @selection-change="handleSelectionChange"
            border
            :height="tableHeight"
            ref="BillTable"
            v-loading="loading"
            :key="isKey"
            row-key="billId"
            :select-on-indeterminate="isCheckAll"
          >
            <el-table-column
              type="selection"
              width="50"
              align="center"
              :reserve-selection="true"
              fixed="left"
            />
            <el-table-column
              label="序号"
              align="center"
              type="index"
              width="50"
              fixed="left"
            />
            <el-table-column
              :label="billNoLabel"
              align="center"
              prop="billNo"
              show-overflow-tooltip
              width="160"
            >
              <template slot-scope="scope">
                <el-button
                  class="copyable-button"
                  @click.native.prevent="handleUpdate(scope.row, routerName)"
                  type="text"
                  >{{ scope.row.billNo }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="单据日期"
              align="center"
              prop="billDate"
              v-if="billType != '000'"
              width="120"
            />
            <el-table-column
              label="单据状态"
              align="center"
              prop="billStatus"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.bill_status"
                  :value="scope.row.billStatus"
                />
              </template>
            </el-table-column>
            <slot name="tableMiddle"></slot>
            <el-table-column
              label="备注"
              align="center"
              prop="billRemark"
              v-if="billType != '000'"
              width="160"
            />
            <el-table-column
              label="审核人"
              align="center"
              prop="auditBy"
              v-if="billType != '000'"
            />
            <el-table-column
              label="审核时间"
              align="center"
              prop="auditTime"
              v-if="billType != '000'"
              show-overflow-tooltip
              width="160"
            />
            <el-table-column
              label="修改人"
              align="center"
              prop="updateBy"
              v-if="billType != '000'"
              show-overflow-tooltip
              width="160"
            />
            <el-table-column
              label="修改时间"
              align="center"
              prop="updateTime"
              v-if="billType != '000'"
              show-overflow-tooltip
              width="160"
            />
            <el-table-column
              label="创建人"
              align="center"
              prop="createBy"
              v-if="billType != '000'"
            />
            <el-table-column
              label="创建时间"
              align="center"
              prop="createTime"
              v-if="billType != '000'"
              show-overflow-tooltip
              width="160"
            />

            <slot name="tableDate"></slot>
          </el-table>
          <div class="bill-footer">
            <div class="left">
              <span class="select">
                已选
                <span class="num">{{ this.ids.length }}</span
                >条
              </span>
              <span class="total">共{{ total || 0 }}条</span>
              <span class="checkAll" v-show="!isCheckAll" @click="selectAll"
                >选择全部</span
              >
              <span class="checkAll" v-show="isCheckAll" @click="selectAll"
                >取消选择</span
              >
            </div>
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="purSelectList.pageNum"
              :limit.sync="purSelectList.pageSize"
              @pagination="getList(urlList)"
            />
          </div>
        </div>
      </template>
    </cardTitleCom>
    <!-- 选择仓库对话框 -->
    <selectStore
      :OpenStore.sync="OpenStore"
      @getStore="getStore($event, 'purSelectList', showStore, 'selectStore')"
    ></selectStore>
    <!-- 新增仓库对话框 -->
    <storeAdd
      :OpenStoreAdd.sync="OpenStoreAdd"
      @getStore="getStore($event, 'purSelectList', showStore, 'selectStore')"
    ></storeAdd>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import selectSupplier from '@/views/components/selectSupplier' //选择供应商组价
import selectStore from '@/views/components/selectStore' //选择仓库组件
import storeAdd from '@/views/components/selectStore/storeAdd.vue' //新增仓库组件
import {
  handleBillStatus,
  getList,
  getBasicData,
  selectAll,
  handleSelectionChange,
  showHighSearch,
  refresh,
  handleAdd,
  handleUpdate,
  reset,
  changeBillType,
  getDiyselectId,
  getSupplier,
  getStore
} from '@/views/components/bill/public'

export default {
  name: 'Master',
  dicts: ['bill_status'],
  components: {
    cardTitleCom,
    storeAdd,
    selectStore
  },
  props: {
    billType: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      //打印
      PrintObj: {
        id: 'print'
      },
      //更新表格视图
      isKey: true,
      //高级搜索图标
      icon: 'el-icon-caret-bottom',
      //是否显示高级搜索
      showHigh: false,
      //表格高度
      tableHeight: '550',
      // 非多个禁用
      multiple: true,
      //单据日期
      datetime: undefined,
      //遮罩层
      loading: false,
      //判断当前是否全选数据
      isCheckAll: false,
      //更新单据状态(审核,反审核,删除,关闭)的url
      url: undefined,
      //出现列表数据的url
      urlList: undefined,
      //跳转明细表的路由名字
      routerName: undefined,
      //单据编号字段
      billNoLabel: undefined,
      //单据类型(销售/采购)
      ItemType: undefined,
      //判断是否是仓库类型
      diySelectType: false,
      //单据细分类型
      BillType: undefined,
      //是否显示的字段
      showFields: undefined,
      //显示仓库(出库入库仓库)
      showStore: undefined,
      //显示采购员或业务员
      showEmployee: undefined,
      //过滤供应商的值
      supplierVal: undefined,
      //过滤仓库的值
      storeVal: undefined,
      //打开选择供应商开关
      openSelectSupplier: false,
      //打开选择仓库开关
      OpenStore: false,
      //打开新增仓库开关
      OpenStoreAdd: false,
      //自定义搜索框字段转换 (供应商)
      normalizerSupp: {
        Id: 'supplierId',
        No: 'supplierNo',
        Name: 'supplierName'
      },
      //自定义搜索框字段转换 (客户)
      normalizerCus: {
        Id: 'customerId',
        No: 'customerNo',
        Name: 'customerName'
      },
      //自定义搜索框字段转换 (仓库)
      normalizerStore: {
        Id: 'storeId',
        No: 'storeNo',
        Name: 'storeName'
      },
      //单据总条数
      total: 0,
      //表格选中的数据
      selectData: [],
      //表格选中的数据的ids
      ids: [],
      //供应商数据
      partnerData: [],
      //仓库数据
      storeData: [],
      //员工数据
      employeeData: [],
      //客户数据
      customerData: [],
      //区分需要的是(客户/供应商)数据
      getCusSupp: undefined,
      //选中单据ids
      // ids: [],
      //单据查询数据
      purSelectList: {
        //页码
        pageNum: 1,
        //页容量
        pageSize: 15,
        //单据开始日期时间
        beginBillDate: '',
        //单据结束日期时间
        endBillDate: '',
        //单据编号
        billNo: '',
        //供应商
        partnerId: '',
        //仓库
        storeId: '',
        //采购员
        purUserId: '',
        //单据状态
        billStatus: '',
        // 制单人
        createEmployeeId: undefined,
        //时间过滤
        filterTime: 'today'
      },
      //单据表格所有数据
      purAllList: [],
      //单据当页表格数据
      purList: []
    }
  },
  watch: {
    //侦听单据日期
    datetime (newVal) {
      if (newVal) {
        if (this.purSelectList.filterTime) {
          this.purSelectList.filterTime = undefined
        }
        this.purSelectList.beginBillDate = newVal[0]
        this.purSelectList.endBillDate = newVal[1]
      }
    },
    //侦听时间
    'purSelectList.filterTime': {
      handler (newVal) {
        if (newVal) {
          if (this.datetime) {
            this.datetime = undefined
            this.purSelectList.beginBillDate = undefined
            this.purSelectList.endBillDate = undefined
          }
          //日期快捷触发
          this.getList(this.urlList)
        }
      },
      immediate: true
    },
    //侦听传过来的单据类型
    billType: {
      handler (newVal) {
        this.BillType = newVal
        this.changeBillType(newVal)
        if (newVal === '120101' || newVal === '140313' || newVal === '120102' || newVal === '120103') {
          this.ItemType = true
        } else {
          this.ItemType = false
        }
        // if (newVal === '203') {
        //   this.ItemType = false
        // }
        //获取单据数据
        this.getList(this.urlList)
      },
      immediate: true
    }
  },

  created () {
    //获取下拉框数据
    this.getBasicData('', this.getCusSupp)
  },
  methods: {
    //根据单据类型更改api
    changeBillType,
    //更新单据状态(审核,反审核,删除,关闭)
    handleBillStatus,
    /** 查询单据列表 */
    getList,
    //获取搜索下拉框数据
    getBasicData,
    //获取自定义搜索框选中的id
    getDiyselectId,
    //点击全选所有数据
    selectAll,
    //表格选择发生变化时触发
    handleSelectionChange,
    //是否显示高级搜索
    showHighSearch,
    //获取选中的供应商
    getSupplier,
    //获取选中的仓库
    getStore,
    //刷新按钮
    refresh,
    //新增单据方法
    handleAdd,
    //点击单据编号获取id 跳转详情页
    handleUpdate,
    //清空搜索区域
    reset
  }
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #f3f3f3;

  .bill-footer {
    display: flex;
    justify-content: space-between;

    .left {
      padding: 32px 16px;

      .select {
        .num {
          color: #1890ff;
        }
      }

      .checkAll {
        color: #1890ff;
        cursor: pointer;
      }

      .total {
        margin: 0 10px;
      }
    }
  }

  .searchItem {
    //margin-top: 3vh;
    display: flex;
    align-items: center;
    text-align: left;
    margin: 0 15px 15px 0;

    &.div-status-item {
      width: 50%;
    }

    .label-name {
      min-width: 90px;
      margin-right: 0.52vw;
      text-align: right;
      text-align-last: justify;
    }

    .el-select,
    .el-input {
      width: 200px;
    }

    &.div-status-item {
      line-height: 34px;
    }

    //重置按钮
    &.resetBtn {
      width: 160px;
      display: flex;
      //justify-content: end;
    }
  }
}
.copyable-button {
  user-select: text;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instore-detail app-container" },
    [
      _c(
        "el-card",
        { staticClass: "card" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "instore-form",
              attrs: {
                model: _vm.form,
                "label-width": "90px",
                "label-position": "right",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "bill-nav",
                  class: {
                    "nav-audit-icon": _vm.form.billStatus == 2,
                    "nav-stop-icon": _vm.stopOrder,
                    "nav-noAudit-icon": _vm.noAuditOrder,
                    "nav-delete-icon": _vm.deleteOrder,
                  },
                },
                [
                  _c("div", { staticClass: "info" }, [
                    _c(
                      "div",
                      { staticClass: "searchItem div-number-item" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "单据编号", prop: "billNo" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "单据编号", disabled: "" },
                              model: {
                                value: _vm.form.billNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "billNo", $$v)
                                },
                                expression: "form.billNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "searchItem" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "单据日期", prop: "billDate" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                disabled: _vm.disabled,
                                "value-format": "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.form.billDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "billDate", $$v)
                                },
                                expression: "form.billDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.BillSaleType
                      ? _c(
                          "div",
                          { staticClass: "searchItem" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: " 供应商 ", prop: "partnerId" },
                              },
                              [
                                _c("SelectFrame", {
                                  ref: "selectFrame",
                                  attrs: {
                                    tableData: _vm.partnerData,
                                    disabled: _vm.disabled,
                                    router: "/partner/supplier/newSupplier",
                                    normalizer: _vm.normalizerSupp,
                                  },
                                  on: {
                                    getPitchId: function ($event) {
                                      return _vm.getDiyselectId(
                                        $event,
                                        "partnerId",
                                        "form"
                                      )
                                    },
                                    click: function ($event) {
                                      _vm.openSelectSupplier = true
                                    },
                                  },
                                  model: {
                                    value: _vm.form.partnerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "partnerName", $$v)
                                    },
                                    expression: "form.partnerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.BillSaleType
                      ? _c(
                          "div",
                          { staticClass: "searchItem" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户", prop: "partnerId" } },
                              [
                                _c("SelectFrame", {
                                  ref: "selectFrame",
                                  attrs: {
                                    tableData: _vm.customerData,
                                    disabled: _vm.disabled,
                                    router: "/partner/supplier/newSupplier",
                                    normalizer: _vm.normalizerCus,
                                  },
                                  on: {
                                    getPitchId: function ($event) {
                                      return _vm.getDiyselectId(
                                        $event,
                                        "partnerId",
                                        "form"
                                      )
                                    },
                                    click: function ($event) {
                                      _vm.openSelectSupplier = true
                                    },
                                  },
                                  model: {
                                    value: _vm.form.partnerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "partnerName", $$v)
                                    },
                                    expression: "form.partnerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.storePostType === "inStoreId"
                      ? _c(
                          "div",
                          { staticClass: "searchItem" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "仓库", prop: "inStoreId" } },
                              [
                                _c("SelectFrame", {
                                  ref: "selectStore",
                                  attrs: {
                                    tableData: _vm.storeData,
                                    disabled: _vm.disabled,
                                    normalizer: _vm.normalizerStore,
                                  },
                                  on: {
                                    getPitchId: function ($event) {
                                      return _vm.getDiyselectId(
                                        $event,
                                        "inStoreId",
                                        "form"
                                      )
                                    },
                                    clickAdd: function ($event) {
                                      _vm.OpenStoreAdd = true
                                    },
                                    click: function ($event) {
                                      _vm.OpenStore = true
                                    },
                                  },
                                  model: {
                                    value: _vm.form.inStoreName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "inStoreName", $$v)
                                    },
                                    expression: "form.inStoreName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.storePostType === "outStoreId"
                      ? _c(
                          "div",
                          { staticClass: "searchItem" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "仓库", prop: "outStoreId" } },
                              [
                                _c("SelectFrame", {
                                  ref: "selectStore",
                                  attrs: {
                                    tableData: _vm.storeData,
                                    disabled: _vm.disabled,
                                    normalizer: _vm.normalizerStore,
                                  },
                                  on: {
                                    getPitchId: function ($event) {
                                      return _vm.getDiyselectId(
                                        $event,
                                        "outStoreId",
                                        "form"
                                      )
                                    },
                                    clickAdd: function ($event) {
                                      _vm.OpenStoreAdd = true
                                    },
                                    click: function ($event) {
                                      _vm.OpenStore = true
                                    },
                                  },
                                  model: {
                                    value: _vm.form.outStoreName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "outStoreName", $$v)
                                    },
                                    expression: "form.outStoreName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.storePostType === "storeId"
                      ? _c(
                          "div",
                          { staticClass: "searchItem" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "仓库", prop: "storeId" } },
                              [
                                _c("SelectFrame", {
                                  ref: "selectStore",
                                  attrs: {
                                    tableData: _vm.storeData,
                                    disabled: _vm.disabled,
                                    normalizer: _vm.normalizerStore,
                                  },
                                  on: {
                                    getPitchId: function ($event) {
                                      return _vm.getDiyselectId(
                                        $event,
                                        "storeId",
                                        "form"
                                      )
                                    },
                                    clickAdd: function ($event) {
                                      _vm.OpenStoreAdd = true
                                    },
                                    click: function ($event) {
                                      _vm.OpenStore = true
                                    },
                                  },
                                  model: {
                                    value: _vm.form.storeName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "storeName", $$v)
                                    },
                                    expression: "form.storeName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "searchItem div-number-item" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "手工单据号", prop: "handBillNo" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: _vm.disabled,
                                placeholder: "手工单据号",
                                oninput:
                                  "value=value.replace(/[^0-9a-zA-Z]/g,'')",
                              },
                              model: {
                                value: _vm.form.handBillNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "handBillNo", $$v)
                                },
                                expression: "form.handBillNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "searchItem" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "部门", prop: "purDeptId" } },
                          [
                            ["110101", "120101", "140313"].includes(
                              _vm.billType
                            )
                              ? _c("treeselect", {
                                  staticClass: "treeselect",
                                  attrs: {
                                    options: _vm.deptData,
                                    "show-count": true,
                                    placeholder: "请选择部门",
                                    disabled: _vm.disabled,
                                  },
                                  model: {
                                    value: _vm.form.purDeptId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "purDeptId", $$v)
                                    },
                                    expression: "form.purDeptId",
                                  },
                                })
                              : _c("treeselect", {
                                  staticClass: "treeselect",
                                  attrs: {
                                    options: _vm.deptData,
                                    "show-count": true,
                                    placeholder: "请选择部门",
                                    disabled: _vm.disabled,
                                  },
                                  model: {
                                    value: _vm.form.deptId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "deptId", $$v)
                                    },
                                    expression: "form.deptId",
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.BillSaleType
                      ? _c(
                          "div",
                          { staticClass: "searchItem" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "采购员",
                                  prop: "purEmployeeId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "全部",
                                      disabled: _vm.disabled,
                                      filterable: "",
                                      remote: "",
                                      "reserve-keyword": "",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$refs.form.validateField(
                                          "purEmployeeId"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.form.purEmployeeId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "purEmployeeId", $$v)
                                      },
                                      expression: "form.purEmployeeId",
                                    },
                                  },
                                  _vm._l(_vm.employeeData, function (item) {
                                    return _c("el-option", {
                                      key: item.employeeId,
                                      attrs: {
                                        label: item.employeeName,
                                        value: item.employeeId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.BillSaleType
                      ? _c(
                          "div",
                          { staticClass: "searchItem" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "业务员",
                                  prop: "saleEmployeeId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "全部",
                                      disabled: _vm.disabled,
                                      filterable: "",
                                      remote: "",
                                      "reserve-keyword": "",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$refs.form.validateField(
                                          "saleEmployeeId"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.form.saleEmployeeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "saleEmployeeId",
                                          $$v
                                        )
                                      },
                                      expression: "form.saleEmployeeId",
                                    },
                                  },
                                  _vm._l(_vm.employeeData, function (item) {
                                    return _c("el-option", {
                                      key: item.employeeId,
                                      attrs: {
                                        label: item.employeeName,
                                        value: item.employeeId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "searchItem Address" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "itemAddress",
                            attrs: { label: "收货地址", prop: "storeAddress" },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "410px" },
                                attrs: {
                                  readonly: "",
                                  placeholder: "请点击右边图标可编辑收货地址",
                                  disabled: _vm.disabled,
                                },
                                model: {
                                  value: _vm.form.storeAddressJoin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "storeAddressJoin", $$v)
                                  },
                                  expression: "form.storeAddressJoin",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-more",
                                  attrs: { slot: "suffix" },
                                  on: { click: _vm.setAddress },
                                  slot: "suffix",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "instore-btn" }, [
                _c(
                  "div",
                  { staticClass: "left-btn" },
                  [
                    _c(
                      "el-row",
                      { staticClass: "mb8", attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 1.5 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  plain: "",
                                  icon: "el-icon-download",
                                  size: "mini",
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitForm(false)
                                  },
                                },
                              },
                              [_vm._v("保存 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 1.5 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-plus",
                                  plain: "",
                                  size: "mini",
                                },
                                on: { click: _vm.AddBill },
                              },
                              [_vm._v("保存并新增 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 1.5 } },
                          [
                            _vm.billType !== "110101" &&
                            _vm.billType !== "120101" &&
                            _vm.billType !== "140313"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      plain: "",
                                      icon: "el-icon-edit",
                                      size: "mini",
                                      disabled: _vm.disabled,
                                    },
                                    on: { click: _vm.selectBills },
                                  },
                                  [_vm._v("引入源单 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 2.5 } },
                          [
                            _c(
                              "el-dropdown",
                              {
                                attrs: {
                                  "split-button": "",
                                  type: "primary",
                                  size: "mini",
                                  plain: "",
                                  icon: "el-icon-circle-check",
                                  disabled: _vm.showAudit,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeBillStatus("审核")
                                  },
                                  command: function ($event) {
                                    return _vm.changeBillStatus("反审核")
                                  },
                                },
                              },
                              [
                                _vm._v(" 审核 "),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "反审核" } },
                                      [_vm._v("反审核")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 1.5 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  plain: "",
                                  icon: "el-icon-switch-button",
                                  size: "mini",
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeBillStatus("关闭")
                                  },
                                },
                              },
                              [_vm._v("关闭 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "right-btn" },
                  [
                    _c(
                      "el-dropdown",
                      {
                        directives: [
                          {
                            name: "print",
                            rawName: "v-print",
                            value: _vm.PrintObj,
                            expression: "PrintObj",
                          },
                        ],
                        attrs: {
                          "split-button": "",
                          type: "primary",
                          size: "mini",
                          plain: "",
                          disabled: "",
                        },
                      },
                      [
                        _vm._v(" 打印 "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "打印预览" } },
                              [_vm._v("打印预览")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "打印设置" } },
                              [_vm._v("打印设置")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "instore-table" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "dragTable",
                              staticClass: "table",
                              attrs: {
                                data: _vm.form.details,
                                "row-key": "columnId",
                                height: "450",
                                border: "",
                                "show-summary": "",
                                "summary-method": _vm.getSummaries,
                              },
                              on: {
                                "cell-mouse-enter": _vm.cellMouseEnter,
                                "cell-mouse-leave": _vm.cellMouseLeave,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "80",
                                  label: _vm.tableCellLabel,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.hoverRow && !_vm.disabled
                                          ? _c("i", {
                                              staticClass:
                                                "el-icon-circle-plus operatePush",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.row(
                                                    "push",
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        scope.row.hoverRow && !_vm.disabled
                                          ? _c("i", {
                                              staticClass:
                                                "el-icon-remove operateDel",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.row(
                                                    "del",
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        !scope.row.hoverRow
                                          ? _c("div", [
                                              _vm._v(_vm._s(scope.$index + 1)),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    label: "商品编码",
                                    width: "160",
                                    align: "center",
                                    prop: "goodsNo",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "details[" +
                                                  scope.$index +
                                                  "].goodsNo",
                                                rules:
                                                  _vm.rules["details.goodsNo"],
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                  },
                                                  on: { blur: _vm.watchScroll },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.keyup(
                                                        "goodsNo",
                                                        $event,
                                                        scope.$index,
                                                        true
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.goodsNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "goodsNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.goodsNo",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "el-icon-more",
                                                    attrs: { slot: "suffix" },
                                                    on: {
                                                      click:
                                                        _vm.openSelectGoods,
                                                    },
                                                    slot: "suffix",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c(
                                      "i",
                                      { staticStyle: { color: "#ff4949" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(" 商品编码 "),
                                  ]),
                                ],
                                2
                              ),
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品名称",
                                  align: "center",
                                  prop: "goodsName",
                                  "show-overflow-tooltip": "",
                                  width: "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "条码",
                                  width: "160",
                                  align: "center",
                                  prop: "barcode",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "规格",
                                  align: "center",
                                  prop: "goodsSpec",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    label: "数量",
                                    align: "center",
                                    width: "120",
                                    prop: "unitQty",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "details[" +
                                                  scope.$index +
                                                  "].unitQty",
                                                rules:
                                                  _vm.rules["details.unitQty"],
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  oninput:
                                                    "value=value.replace(/[^0-9.]/g,'')",
                                                  disabled: _vm.disabled,
                                                  sunyunFormat: function (v) {
                                                    return _vm.$syInput(1, v)
                                                  },
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.handleEdit(
                                                      scope.row,
                                                      "unitQty",
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.unitQty,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "unitQty",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.unitQty",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c(
                                      "i",
                                      { staticStyle: { color: "#ff4949" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(" 数量 "),
                                  ]),
                                ],
                                2
                              ),
                              _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    label: "单位",
                                    align: "center",
                                    width: "120",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "details[" +
                                                  scope.$index +
                                                  "].unitId",
                                                rules:
                                                  _vm.rules["details.unitId"],
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.$refs.form.validateField(
                                                        "details[" +
                                                          scope.$index +
                                                          "].unitId"
                                                      )
                                                    },
                                                    change: function ($event) {
                                                      return _vm.changeUnitPrice(
                                                        scope.row.unitId,
                                                        scope.row.units,
                                                        scope.$index,
                                                        true
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.unitId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "unitId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.unitId",
                                                  },
                                                },
                                                _vm._l(
                                                  scope.row.units,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.unitId,
                                                      attrs: {
                                                        label:
                                                          item.unitName ==
                                                          item.goodsSpec
                                                            ? item.unitName
                                                            : item.unitName +
                                                              " / " +
                                                              item.goodsSpec,
                                                        value: item.unitId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c(
                                      "i",
                                      { staticStyle: { color: "#ff4949" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(" 单位 "),
                                  ]),
                                ],
                                2
                              ),
                              _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    label: "单价(元)",
                                    align: "center",
                                    width: "120",
                                    "value-key": "duide",
                                    prop: "unitPrice",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "details[" +
                                                  scope.$index +
                                                  "].unitPrice",
                                                rules:
                                                  _vm.rules[
                                                    "details.unitPrice"
                                                  ],
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  sunyunFormat: function (v) {
                                                    return _vm.$syInput(2, v)
                                                  },
                                                  oninput:
                                                    "value=value.replace(/[^0-9.]/g,'')",
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    return _vm.showFormatting(
                                                      3,
                                                      "focus",
                                                      $event,
                                                      _vm.showForm.details,
                                                      _vm.form.details,
                                                      "unitPrice",
                                                      scope.$index
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.allFun(
                                                      scope.row,
                                                      "unitPrice",
                                                      $event,
                                                      "blur",
                                                      "unitPrice",
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.showForm.details[
                                                      scope.$index
                                                    ].unitPrice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.showForm.details[
                                                        scope.$index
                                                      ],
                                                      "unitPrice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "showForm.details[scope.$index].unitPrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c(
                                      "i",
                                      { staticStyle: { color: "#ff4949" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(" 单价(元) "),
                                  ]),
                                ],
                                2
                              ),
                              _c("el-table-column", {
                                attrs: {
                                  label: "含税单价(元)",
                                  align: "center",
                                  width: "120",
                                  prop: "unitTaxPrice",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            sunyunFormat: function (v) {
                                              return _vm.$syInput(2, v)
                                            },
                                            oninput:
                                              "value=value.replace(/[^0-9.]/g,'')",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.allFun(
                                                scope.row,
                                                "unitTaxPrice",
                                                $event,
                                                "blur",
                                                "unitTaxPrice",
                                                scope.$index
                                              )
                                            },
                                            focus: function ($event) {
                                              return _vm.showFormatting(
                                                3,
                                                "focus",
                                                $event,
                                                _vm.showForm.details,
                                                _vm.form.details,
                                                "unitTaxPrice",
                                                scope.$index
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.showForm.details[scope.$index]
                                                .unitTaxPrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.showForm.details[
                                                  scope.$index
                                                ],
                                                "unitTaxPrice",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "showForm.details[scope.$index].unitTaxPrice",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "税率(%)",
                                  align: "center",
                                  prop: "taxRate",
                                  width: "120",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            oninput:
                                              "value=value.replace(/[^0-9.]/g,'')",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.allFun(
                                                scope.row,
                                                "taxRate",
                                                $event,
                                                "blur",
                                                "taxRate",
                                                scope.$index
                                              )
                                            },
                                            focus: function ($event) {
                                              return _vm.showFormatting(
                                                3,
                                                "focus",
                                                $event,
                                                _vm.showForm.details,
                                                _vm.form.details,
                                                "taxRate",
                                                scope.$index
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.showForm.details[scope.$index]
                                                .taxRate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.showForm.details[
                                                  scope.$index
                                                ],
                                                "taxRate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "showForm.details[scope.$index].taxRate",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "税额",
                                  align: "center",
                                  width: "120",
                                  prop: "unitTaxMoney",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$syInput(
                                                3,
                                                _vm.callUnitTaxMoney(scope.row)
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "价税合计(元)",
                                  align: "center",
                                  prop: "unitMoney",
                                  width: "120",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$syInput(
                                                3,
                                                _vm.calUnitPriceTaxMoney(
                                                  scope.row
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品批号",
                                  align: "center",
                                  width: "120",
                                  prop: "batchNo",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            oninput:
                                              "value=value.replace(/[^0-9]/g,'')",
                                          },
                                          model: {
                                            value: scope.row.batchNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "batchNo",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.batchNo",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "附加费",
                                  align: "center",
                                  width: "120",
                                  prop: "attachMoney",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          ref: "attachMoney",
                                          attrs: {
                                            disabled: _vm.disabled,
                                            sunyunFormat: function (v) {
                                              return _vm.$syInput(3, v)
                                            },
                                            oninput:
                                              "value=value.replace(/[^0-9.]/g,'')",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.showFormatting(
                                                3,
                                                "blur",
                                                $event,
                                                _vm.showForm.details,
                                                _vm.form.details,
                                                "attachMoney",
                                                scope.$index
                                              )
                                            },
                                            focus: function ($event) {
                                              return _vm.showFormatting(
                                                3,
                                                "focus",
                                                $event,
                                                _vm.showForm.details,
                                                _vm.form.details,
                                                "attachMoney",
                                                scope.$index
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.showForm.details[scope.$index]
                                                .attachMoney,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.showForm.details[
                                                  scope.$index
                                                ],
                                                "attachMoney",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "showForm.details[scope.$index].attachMoney",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._t("tableSpecial"),
                              _vm._t("tableBillSource"),
                              _c("el-table-column", {
                                attrs: {
                                  label: "备注",
                                  align: "center",
                                  width: "200",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: { disabled: _vm.disabled },
                                          model: {
                                            value: scope.row.remark,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "remark", $$v)
                                            },
                                            expression: "scope.row.remark",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "instore-footer" },
                [
                  _c("accessoryUpload", {
                    attrs: { disabled: _vm.disabled, fileList: _vm.fileList },
                    on: {
                      getFileItems: _vm.getFileItemsData,
                      delFileItems: _vm.delFileItems,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "right-remark" },
                    [
                      _c("span", [_vm._v(" 单据备注:")]),
                      _c("el-input", {
                        staticClass: "input",
                        attrs: {
                          placeholder: "备注",
                          disabled: _vm.disabled,
                          type: "textarea",
                          rows: 3,
                        },
                        model: {
                          value: _vm.form.billRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "billRemark", $$v)
                          },
                          expression: "form.billRemark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("SelectGoods", {
            attrs: {
              OpenGoods: _vm.OpenGoods,
              queryCode: _vm.queryCode,
              searchType: _vm.searchType,
              isEnablePurchase: _vm.isEnablePurchase,
              isEnableSale: _vm.isEnableSale,
            },
            on: {
              "update:OpenGoods": function ($event) {
                _vm.OpenGoods = $event
              },
              "update:open-goods": function ($event) {
                _vm.OpenGoods = $event
              },
              getGoodsIds: function ($event) {
                return _vm.getSelectIds($event, true)
              },
            },
          }),
          _c("SelectBills", {
            attrs: {
              title: _vm.title,
              NoFields: _vm.NoFields,
              tableColumnFields: _vm.tableColumnFields,
              billList: _vm.billList,
              openDialog: _vm.openBillDia,
              loadingDia: _vm.loadingDia,
              billTotal: _vm.billTotal,
              allBillList: _vm.allBillList,
              billType: _vm.billType,
            },
            on: {
              "update:openDialog": function ($event) {
                _vm.openBillDia = $event
              },
              "update:open-dialog": function ($event) {
                _vm.openBillDia = $event
              },
              getSelect: _vm.selectBills,
              getAllBills: _vm.getSelectBills,
              getBillsIds: function ($event) {
                return _vm.getSelectIds($event, true)
              },
            },
          }),
          _c("selectSupplier", {
            attrs: { openSelectSupplier: _vm.openSelectSupplier },
            on: {
              "update:openSelectSupplier": function ($event) {
                _vm.openSelectSupplier = $event
              },
              "update:open-select-supplier": function ($event) {
                _vm.openSelectSupplier = $event
              },
              getSupplierId: function ($event) {
                return _vm.getSupplier($event, "form")
              },
            },
          }),
          _c("selectStore", {
            attrs: { OpenStore: _vm.OpenStore },
            on: {
              "update:OpenStore": function ($event) {
                _vm.OpenStore = $event
              },
              "update:open-store": function ($event) {
                _vm.OpenStore = $event
              },
              getStore: function ($event) {
                return _vm.getStore($event, "form", "", "selectStore")
              },
            },
          }),
          _c("storeAdd", {
            attrs: { OpenStoreAdd: _vm.OpenStoreAdd },
            on: {
              "update:OpenStoreAdd": function ($event) {
                _vm.OpenStoreAdd = $event
              },
              "update:open-store-add": function ($event) {
                _vm.OpenStoreAdd = $event
              },
              getStore: function ($event) {
                return _vm.getStore($event, "form")
              },
            },
          }),
          _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "dialogDrag",
                  rawName: "v-dialogDrag",
                  value: true,
                  expression: "true",
                },
              ],
              staticClass: "AddressDialog",
              attrs: {
                title: "收货地址",
                visible: _vm.openAddress,
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.openAddress = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "AddressForm",
                  attrs: {
                    model: _vm.form.storeAddress,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系人", prop: "contactMan" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "请输入联系人",
                                  maxlength: "30",
                                },
                                model: {
                                  value: _vm.form.storeAddress.contactMan,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.storeAddress,
                                      "contactMan",
                                      $$v
                                    )
                                  },
                                  expression: "form.storeAddress.contactMan",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "联系电话", prop: "contactTel" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "请输入联系电话",
                                  maxlength: "30",
                                },
                                model: {
                                  value: _vm.form.storeAddress.contactTel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.storeAddress,
                                      "contactTel",
                                      $$v
                                    )
                                  },
                                  expression: "form.storeAddress.contactTel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "门店地址",
                                prop: "storeAddress",
                              },
                            },
                            [
                              _c("el-cascader", {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading,
                                    expression: "loading",
                                  },
                                ],
                                ref: "cascader",
                                staticClass: "provinceListClass",
                                attrs: {
                                  options: _vm.provinceList,
                                  props: _vm.defaultProp,
                                  maxlength: "80",
                                  emitPath: "",
                                },
                                on: { change: _vm.getAddressId },
                                model: {
                                  value: _vm.form.storeAddress.regionId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.storeAddress,
                                      "regionId",
                                      $$v
                                    )
                                  },
                                  expression: "form.storeAddress.regionId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "详细地址",
                                prop: "addressDetail",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "详细地址",
                                  maxlength: "30",
                                },
                                model: {
                                  value: _vm.form.storeAddress.addressDetail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.storeAddress,
                                      "addressDetail",
                                      $$v
                                    )
                                  },
                                  expression: "form.storeAddress.addressDetail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveAddress },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.openAddress = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "left" }),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("单据金额 :")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.billMoney)),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
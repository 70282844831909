var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-bill" },
    [
      _c(
        "CDialog",
        {
          attrs: {
            title: _vm.title,
            showDialog: _vm.openDialog,
            width: 1100,
            levelType: "",
          },
          on: {
            "update:showDialog": function ($event) {
              _vm.openDialog = $event
            },
            "update:show-dialog": function ($event) {
              _vm.openDialog = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c(
                "div",
                { staticClass: "top" },
                [
                  this.billType == "130105"
                    ? _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.queryParams.sourceBillType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "sourceBillType", $$v)
                            },
                            expression: "queryParams.sourceBillType",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: 140301 } }, [
                            _vm._v("门店订货"),
                          ]),
                          _c("el-radio-button", { attrs: { label: 140302 } }, [
                            _vm._v("门店原料订货"),
                          ]),
                          _c("el-radio-button", { attrs: { label: 170207 } }, [
                            _vm._v("其他订货"),
                          ]),
                          _c("el-radio-button", { attrs: { label: 170208 } }, [
                            _vm._v("其他原料订货"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("searchBox", {
                    ref: "searchBox",
                    staticClass: "marL10 marR10",
                    attrs: {
                      dropDownList: _vm.dropDownList,
                      placeholderText: "请输入",
                    },
                    on: {
                      parameter: _vm.searchClick,
                      searchTextInput: _vm.searchTextInput,
                      searchInputEnter: _vm.searchInputEnter,
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.searchInputEnter },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tableData" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingDia,
                          expression: "loadingDia",
                        },
                      ],
                      ref: "billsTable",
                      staticClass: "table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.billList,
                        height: "500",
                        border: "",
                        "max-height": "500",
                        "row-key":
                          _vm.billType == "130106"
                            ? "sourceBillId"
                            : "billDetailId",
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChange,
                        select: _vm.selectChange,
                        "select-all": _vm.selectAllChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "50",
                          align: "center",
                          "reserve-selection": true,
                        },
                      }),
                      _vm.billType !== "130106"
                        ? _c("el-table-column", {
                            attrs: {
                              label: _vm.NoFields[0].label,
                              align: "center",
                              prop: _vm.NoFields[0].name,
                              "show-overflow-tooltip": true,
                              sortable: "",
                              width: "160",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "use-select",
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row[_vm.NoFields[0].name]
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              854127486
                            ),
                          })
                        : _vm._e(),
                      _vm.billType !== "130106"
                        ? _c("el-table-column", {
                            attrs: {
                              label: _vm.NoFields[1].label,
                              align: "center",
                              prop: _vm.NoFields[1].name,
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("dict-tag", {
                                        attrs: {
                                          options: _vm.dict.type.bill_status,
                                          value: scope.row.billStatus,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              696661852
                            ),
                          })
                        : _vm._e(),
                      ["130105"].includes(_vm.billType)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "单据日期",
                              align: "center",
                              prop: "billDate",
                              "show-overflow-tooltip": true,
                              width: "120",
                            },
                          })
                        : _vm._e(),
                      ["130105"].includes(_vm.billType)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "订货仓库",
                              align: "center",
                              prop: "storeName",
                              "show-overflow-tooltip": true,
                              width: "120",
                            },
                          })
                        : _vm._e(),
                      _vm._l(_vm.tableColumnFields, function (item, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: item.label,
                            align: "center",
                            prop: item.name,
                            "show-overflow-tooltip": true,
                            width: "120",
                          },
                        })
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      ref: "tag",
                      staticClass: "tag",
                      on: { click: _vm.showPitchBills },
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.openSelectBills,
                            expression: "!openSelectBills",
                          },
                        ],
                        staticClass: "el-icon-arrow-left",
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.openSelectBills,
                            expression: "openSelectBills",
                          },
                        ],
                        staticClass: "el-icon-arrow-right",
                      }),
                      _c("div", { staticClass: "right" }, [
                        _c("div", [_vm._v("已")]),
                        _c("div", [_vm._v("选")]),
                        _c("span", [_vm._v(_vm._s(_vm.ids.length))]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.openSelectBills,
                          expression: "openSelectBills",
                        },
                      ],
                      staticClass: "pitch",
                    },
                    [
                      _c("div", { staticClass: "pitch-title" }, [
                        _c("div", { staticClass: "left" }, [
                          _vm._v("选中的单据"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "right",
                            on: {
                              click: function ($event) {
                                return _vm.delPitchBills("#")
                              },
                            },
                          },
                          [_vm._v("清空")]
                        ),
                      ]),
                      _c("el-scrollbar", { staticClass: "scrollbarPitch" }, [
                        _c(
                          "div",
                          { staticClass: "itemList" },
                          _vm._l(_vm.selectAllData, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.billDetailId,
                                staticClass: "item-goods",
                              },
                              [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s(item.goodsName)),
                                ]),
                                _c("div", {
                                  staticClass: "el-icon-close",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delPitchBills(item)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("bottomPage", {
                attrs: {
                  getList: _vm.getList,
                  idsCom: _vm.ids,
                  totalCom: _vm.billTotal,
                  isCheck: this.selectAllData.length ? true : false,
                },
                on: { selectAllCom: _vm.bottomPageSelectAll },
                model: {
                  value: _vm.queryParams,
                  callback: function ($$v) {
                    _vm.queryParams = $$v
                  },
                  expression: "queryParams",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "buttonList" }, slot: "buttonList" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.getBills } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
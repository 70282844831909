<template>
  <!-- 授权绑定 -->
  <div class="authorizationBinding">
    <div class="content">
      <div class="header x-fc">
        正在授权
        <div class="icon">
          <img src="@/assets/imgs/loginimgs/weixin-icon.svg" alt="加载失败" />
        </div>
        与
        <div class="icon">
          <img src="@/assets/imgs/loginimgs/product-icon.png" alt="加载失败" />
        </div>
        进行绑定
      </div>
      <div class="choose-wrapper">
        <div class="block">
            <div class="desc">
                <strong class="title">注册新的账号</strong>
            <div>通过此流程进行注册的账号</div>
            <div>将直接登录并与第三方账号完成绑定</div>
            </div>
           <div class="btn">
            <el-button type="primary">新注册</el-button>
           </div>
        </div>
        <div class="block">
            <div class="desc">
                <strong class="title">绑定已有的账号</strong>
            <div>登录已有账号与第三方账号完成绑定</div>
            </div>
            <div class="btn">
                <el-button type="primary">绑定</el-button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.authorizationBinding {
  height: 100%;
  background-color: #f6f6f6;
  padding: 10% 0;
  .content {
    width: 80%;
    margin: 0 auto;
    .header {
      height: 80px;
      .icon {
        margin: 0 15px;
      }
    }
    .choose-wrapper {
      display: flex;
      justify-content: space-between;
      .block {
        font-size: 14px;
        width: 48%;
        height: 180px;
        background-color: #fff;
        text-align: center;
        border: 1px solid #eaeff3;
        padding: 32px 0 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
      }
    }
  }
}
</style>
